import { createSlice } from '@reduxjs/toolkit';
import { clearStore, setModal } from './reducer';
import { IModalStore } from './types';

const initialState: IModalStore = {};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal,
    clearStore,
  },
});

export const actions = modalSlice.actions;
export const reducer = modalSlice.reducer;
