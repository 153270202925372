import { ENVIRONTYPE } from 'types/env';
import { keycloakProvider, samoletProvider } from './providers';
import { IAuthProvider } from './type';

export const envProvidersMap: Record<ENVIRONTYPE, IAuthProvider> = {
  SAAS: keycloakProvider,
  SAMOLET: samoletProvider,
};

export const DEFAULT_PROVIDER = envProvidersMap.SAMOLET;
