import {
  EAnalyticsLoggerActions,
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerEvents,
  EAnalyticsLoggerParams,
  type IAnalyticsLoggerCase,
  type IAnalyticsLoggerCaseSettings,
  type TAnalyticsLoggerBaseDict,
  type TAnalyticsLoggerCase,
  type TAnalyticsLoggerContext,
  type TAnalyticsLoggerErrorText,
  type TAnalyticsLoggerExternalUser,
  type TAnalyticsLoggerItemId,
  type TAnalyticsLoggerItemType,
  type TAnalyticsLoggerResult,
  type TAnalyticsLoggerStep,
} from './types';

/**
 * Кейс аналитики
 */
class AnalyticsLoggerCase<T extends TAnalyticsLoggerBaseDict>
  implements IAnalyticsLoggerCase<T>
{
  private _data: TAnalyticsLoggerCase<T>;

  private _name: EAnalyticsLoggerEventNames;

  constructor(settings: IAnalyticsLoggerCaseSettings) {
    this._name = settings.name;
    this._data = {} as TAnalyticsLoggerCase<T>;
  }

  getData() {
    return this._data;
  }

  getName() {
    return this._name;
  }

  event(value: EAnalyticsLoggerEvents): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.Event] = value;

    return this;
  }

  userId(value: number): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.UserId] = value;

    return this;
  }

  externalUser(value: TAnalyticsLoggerExternalUser): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.ExternalUser] = value;

    return this;
  }

  action(value: EAnalyticsLoggerActions): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.Action] = value;

    return this;
  }

  context(value: TAnalyticsLoggerContext): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.Context] = value;

    return this;
  }

  itemType(value: TAnalyticsLoggerItemType): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.ItemType] = value;

    return this;
  }

  itemId(value: TAnalyticsLoggerItemId): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.ItemId] = value;

    return this;
  }

  step(value: TAnalyticsLoggerStep): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.Step] = value;

    return this;
  }

  result(value: TAnalyticsLoggerResult): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.Result] = value;

    return this;
  }

  errorText(value: TAnalyticsLoggerErrorText): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.ErrorText] = value;

    return this;
  }

  dictionary(value: T): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.Dictionary] = value;

    return this;
  }

  extraParams(value: T): IAnalyticsLoggerCase<T> {
    this._data[EAnalyticsLoggerParams.ExtraParams] = value;

    return this;
  }
}

export default AnalyticsLoggerCase;
