import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TVersion, queryKey } from 'api/types';
import { getAppVersion } from 'api/api';

interface IUseCheckVersion {
  appVersion?: TVersion;
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
}

export const useCheckVersion = (): IUseCheckVersion => {
  const {
    data: appVersion,
    isFetching,
    isFetched,
    isLoading: isCheckLoading,
  } = useQuery([queryKey.getAppVersion], () => getAppVersion());

  const isLoading = useMemo(
    () => isCheckLoading || isFetching,
    [isCheckLoading, isFetching],
  );

  return { appVersion, isLoading, isFetching, isFetched };
};
