import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import type { IListArrayModel, IListArrayModelSettings } from './types';

@injectable()
class ListArrayModel<T> implements IListArrayModel<T> {
  @observable
  items: T[];

  constructor(settings: IListArrayModelSettings<T>) {
    this.items = settings.items || [];
    makeObservable(this);
  }

  @computed
  get length() {
    return this.items.length;
  }

  addItems = (items: T[]) => {
    items.forEach(this.addItem);
  };

  @action
  addOnce = (item: T): void => {
    if (this.items.includes(item)) return;

    this.addItem(item);
  };

  @action
  addItem = (item: T): void => {
    this.items.push(item);
  };

  @action
  removeItem = (item: T): void => {
    const index = this.items.indexOf(item);
    if (index === -1) return;
    this.items.splice(index, 1);
  };

  @action
  setItems = (items: T[]): void => {
    this.items = items;
  };

  @action
  clear = (): void => {
    this.items = [];
  };

  toArray = () => {
    return this.items;
  };
}

export default ListArrayModel;
