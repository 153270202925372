import { action, makeObservable, observable } from 'mobx';
import type { IAsyncStore, IAsyncStoreSettings } from './types';

class AsyncStore<Data> implements IAsyncStore<Data> {
  @observable
  isLoading = false;

  @observable
  isLoaded = false;

  private _getter: IAsyncStoreSettings<Data>['get'];

  constructor(settings: IAsyncStoreSettings<Data>) {
    this._getter = settings.get;
    makeObservable(this);
  }

  @action
  load = async (...args: any[]): Promise<Data> => {
    try {
      this.isLoading = true;

      const result = await this._getter(...args);

      this.isLoading = false;
      this.isLoaded = true;

      return result;
    } finally {
      this.isLoading = false;
      this.isLoaded = false;
    }
  };
}

export default AsyncStore;
