import { API_PROFILE } from 'api/routes';
import { createApi } from 'api/base';
import { IProfileExtended } from 'types/user';
import { revocableGet } from 'api/request';

const createProfileApi = () => {
  const apiPath = API_PROFILE;
  const get = createApi(
    (token): Promise<IProfileExtended> => revocableGet(apiPath, null, token),
  );

  return {
    get,
  };
};

export default createProfileApi();
