import { injectable } from 'inversify';
import { merge } from 'lodash';
import { isEmpty } from 'helpers/object';
import type { IApiBufferService, IApiBufferServiceSettings } from './types';

const defaultTimeout = 500;

@injectable()
class ApiBufferService<T extends object = object> implements IApiBufferService<T> {
  // #region Properties (6)

  private _data: Partial<T> = {};

  private _onBufferCallback: IApiBufferServiceSettings<T>['onBufferReady'];

  private _onTimeOut =
    (
      resolve: (value: T | PromiseLike<T> | undefined) => void,
      reject: (reason?: any) => void,
    ) =>
    () => {
      const oldData = { ...this._data };
      this._data = {};
      this._onBufferCallback(oldData).then(resolve).catch(reject);
    };

  private _refreshTimer = (
    resolve: (value: T | PromiseLike<T> | undefined) => void,
    reject: (reason?: any) => void,
  ) => {
    this._timer = setTimeout(this._onTimeOut(resolve, reject), this._timeout) as any;
  };

  private _timeout: number;

  private _timer: NodeJS.Timeout;

  // #endregion Properties (6)

  // #region Constructors (1)

  constructor(settings: IApiBufferServiceSettings<T>) {
    this._timeout = settings?.timeout ?? defaultTimeout;
    this._onBufferCallback = settings?.onBufferReady;
  }

  // #endregion Constructors (1)

  // #region Public Methods (1)

  public change = (data: Partial<T>): void => {
    this._data = merge(this._data, data);
  };

  has = (key: keyof T): boolean => {
    return key in this._data;
  };

  public releaseChanges = (): Promise<T | undefined> => {
    return new Promise<T | undefined>((resolve, reject) => {
      if (isEmpty(this._data)) return Promise.resolve(undefined);
      clearTimeout(this._timer);
      this._refreshTimer(resolve, reject);
    });
  };

  // #endregion Public Methods (1)
}

export default ApiBufferService;
