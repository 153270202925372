import { useEffect, useMemo } from 'react';
import { queryClient } from 'api/queryClient';

const config = {
  childList: true,
  attributes: true,
};

export const useInitialRequestsToCache = () => {
  const statusElement = document.querySelector('#QUERIES_CACHE_STATUS') as HTMLElement;
  const isUpdated = Boolean(parseInt(statusElement?.dataset?.status ?? '0', 10));

  const doSync = () => {
    for (const [key, value] of Object.entries((window as any).QUERIES_CACHE_KEY)) {
      queryClient.setQueryData([key], value);
    }
  };

  const observer = useMemo(() => new MutationObserver(doSync), []);

  useEffect(() => {
    if (isUpdated) {
      doSync();

      return;
    }

    observer.observe(statusElement, config);

    return () => observer.disconnect();
  }, [statusElement, isUpdated, observer]);
};
