import { inject, injectable } from 'inversify';
import { EGlobalDIKeys } from 'core/di/keys';
import type {
  IAnalyticsLoggerCase,
  IAnalyticsLoggerCaseRegistrator,
  IAnalyticsLoggerRegistrator,
  TAnalyticsLoggerBaseDict,
} from './types';

@injectable()
class AnalyticsLoggerCaseRegistrator implements IAnalyticsLoggerCaseRegistrator {
  constructor(
    @inject(EGlobalDIKeys.AnalyticsLogger)
    private readonly _logger: IAnalyticsLoggerRegistrator,
  ) {}

  register<T extends TAnalyticsLoggerBaseDict>(arg: IAnalyticsLoggerCase<T>) {
    this._logger.register(arg.getName(), arg.getData());
  }
}

export default AnalyticsLoggerCaseRegistrator;
