import { KeyboardEvent, MouseEvent, useCallback, useState } from 'react';

export const useModal = (initialValue = false) => {
  const [visible, setVisible] = useState(initialValue);

  const handleShow = useCallback((evt?: MouseEvent<HTMLElement>) => {
    if (evt) evt.persist ? evt.persist() : evt.stopPropagation();

    setVisible(true);
  }, []);

  const handleHide = useCallback((evt?: MouseEvent | KeyboardEvent) => {
    if (evt) evt.persist ? evt.persist() : evt.stopPropagation();

    setVisible(false);
  }, []);

  return { visible, show: handleShow, hide: handleHide };
};
