import { useInjection } from 'inversify-react';
import { EGlobalDIKeys } from 'core/di/keys';
import { IAnalyticsLoggerCaseRegistrator } from 'services/logger/AnalyticsLoggerService/types';
import { IAnalyticsLoggerController } from './types';
export * from 'services/logger/AnalyticsLoggerService/types';
export { caseFactory } from 'services/logger/AnalyticsLoggerService/utils';

const useAnalyticsLogger = () => {
  return useInjection<IAnalyticsLoggerController>(EGlobalDIKeys.AnalyticsLogger);
};

export const useAnalyticsCasesRegistrator = () => {
  return useInjection<IAnalyticsLoggerCaseRegistrator>(
    EGlobalDIKeys.AnalyticsCasesRegistrator,
  );
};

export default useAnalyticsLogger;
