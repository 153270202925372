import { inject, injectable } from 'inversify';
import { action, makeAutoObservable, observable } from 'mobx';
import { QueryObserver, QueryObserverResult } from '@tanstack/react-query';
import { ICatalogRegion } from 'api/api/core/types';
import type { IListMapModel } from 'models/List/types';
import { queryClient } from 'api/queryClient';
import { queryKey } from 'api/types';
import { EGlobalDIKeys } from 'core/di/keys';
import type { IListMapFactoryService } from 'services/list/types';
import { IRegionsObserver } from './types';

@injectable()
class RegionsObserver implements IRegionsObserver {
  @observable
  list: IListMapModel<ICatalogRegion>;

  private _queryObserver: QueryObserver<unknown, unknown, ICatalogRegion[]>;

  constructor(
    @inject(EGlobalDIKeys.ListMapModelFactoryService)
    private readonly _listMapFactory: IListMapFactoryService,
  ) {
    this.list = this._listMapFactory.create({
      items: [] as ICatalogRegion[],
      byKey: 'id',
    });

    this._queryObserver = new QueryObserver<unknown, unknown, ICatalogRegion[]>(
      queryClient,
      {
        queryKey: [queryKey.catalogRegionsList],
        enabled: false,
      },
    );

    this.unsubscribe = this._queryObserver.subscribe(this._callback);

    makeAutoObservable(this);
  }

  private _callback = (result: QueryObserverResult<ICatalogRegion[]>) => {
    if (result.status === 'success') {
      this._update(result.data);
    }
  };

  unsubscribe: () => void;

  @action
  private _update(items: ICatalogRegion[]) {
    this.list.setItems(items);
  }
}

export default RegionsObserver;
