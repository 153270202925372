/* istanbul ignore file */

import store from 'store';
import { TToggleStore } from './types';
import { toggleActions } from './slice';
import { selectEnabled } from './selectors';

export class ToggleConsoleService {
  enable = (name: keyof TToggleStore) => {
    store.dispatch(toggleActions.enable(name));
  };

  disable = (name: keyof TToggleStore) => {
    store.dispatch(toggleActions.disable(name));
  };

  isEnabled = (name: keyof TToggleStore) => {
    return selectEnabled(name)(store.getState());
  };

  getFeatures = () => {
    return Object.keys(store.getState().toggleStore);
  };
}
