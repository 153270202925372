import { Centrifuge } from 'centrifuge';

export interface IWebsockets {
  ws: Centrifuge | null;
}

export enum CENTRIFUGE_ERRORS {
  TIMEOUT = 'TIMEOUT',
  UNKNOWN_ERROR_CODE_5 = 'UNKNOWN_ERROR_CODE_5',
  JWT_TOKEN_IS_INVALID = 'JWT_TOKEN_IS_INVALID',
  TRANSPORT_CLOSED = 'TRANSPORT_CLOSED',
  CONNECTION_CLOSED = 'CONNECTION_CLOSED',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export const centrifugeErrorsCodes: Record<CENTRIFUGE_ERRORS, number> = {
  [CENTRIFUGE_ERRORS.UNKNOWN_ERROR_CODE_5]: 5,
  [CENTRIFUGE_ERRORS.TIMEOUT]: 1,
  [CENTRIFUGE_ERRORS.TRANSPORT_CLOSED]: 2,
  [CENTRIFUGE_ERRORS.JWT_TOKEN_IS_INVALID]: 6,
  [CENTRIFUGE_ERRORS.CONNECTION_CLOSED]: 11,
  [CENTRIFUGE_ERRORS.TOKEN_EXPIRED]: 109,
};

export const nonErrorsCodes = [
  centrifugeErrorsCodes[CENTRIFUGE_ERRORS.UNKNOWN_ERROR_CODE_5],
  centrifugeErrorsCodes[CENTRIFUGE_ERRORS.TIMEOUT],
  centrifugeErrorsCodes[CENTRIFUGE_ERRORS.TRANSPORT_CLOSED],
  centrifugeErrorsCodes[CENTRIFUGE_ERRORS.CONNECTION_CLOSED],
  centrifugeErrorsCodes[CENTRIFUGE_ERRORS.JWT_TOKEN_IS_INVALID],
  centrifugeErrorsCodes[CENTRIFUGE_ERRORS.TOKEN_EXPIRED],
];
