export const getLocalStorage = <T>(key: string): T | undefined => {
  const stringifyValue = localStorage.getItem(key);
  try {
    return stringifyValue ? JSON.parse(stringifyValue) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const setLocalStorage = (key: string, value: string | null) => {
  if (!value) return window.localStorage.removeItem(key);
  window.localStorage.setItem(key, value);
};
