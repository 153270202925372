import React from 'react';
import { Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { queryClient } from 'api/queryClient';
import { WithDI } from 'Root';
import store from 'store';
import DIProvider from 'core/di/Container';
import { AuthProvider } from 'context/Auth';
import { AppContextProvider } from 'context/App/AppContext';

export const history = createBrowserHistory();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router history={history}>
          <DIProvider>
            <AuthProvider>
              <AppContextProvider>
                <WithDI />
              </AppContextProvider>
            </AuthProvider>
          </DIProvider>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
