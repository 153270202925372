import { CustomReducer } from 'types/common';
import { DECISION_TYPE, STATUS_CELL } from 'types/tender';
import { getFullFields, getNewColumnStatus, getNextStatus } from './helpers';
import { IWinnerTableStore } from './types';

export const setIsLoadingTable: CustomReducer<IWinnerTableStore, boolean> = (
  state,
  action,
) => ({
  ...state,
  isLoadingTable: action.payload,
});

export const setInitialValues: CustomReducer<IWinnerTableStore, IWinnerTableStore> = (
  state,
  action,
) => {
  const { data, decisionType, columns, isLoadingTable, showRowVolume, differentVolume } =
    action.payload;

  return {
    ...state,
    data,
    decisionType,
    columns,
    isLoadingTable,
    showRowVolume,
    differentVolume,
  };
};

export const setDecisionType: CustomReducer<IWinnerTableStore, DECISION_TYPE> = (
  state,
  action,
) => ({
  ...state,
  decisionType: action.payload,
});

export const changeCellStatus: CustomReducer<IWinnerTableStore, any> = (
  state,
  action,
) => {
  const { proposalId, cellId, approvedVolume } = action.payload;

  const newData = state.data.map(row => {
    if (row.id !== cellId) return row;

    const proposalRow = { ...row[`proposal_${proposalId}`] };

    return getFullFields({
      ...row,
      [`proposal_${proposalId}`]: {
        ...proposalRow,
        approvedVolume: approvedVolume,
        status: getNextStatus(
          proposalRow.status,
          state.decisionType === DECISION_TYPE.DISTRIBUTE_VOLUMES,
        ),
      },
    });
  });

  if (state.decisionType === DECISION_TYPE.DISTRIBUTE_VOLUMES) {
    return {
      ...state,
      data: newData,
    };
  }

  const newColumns = state.columns.map(column => {
    if (column.id !== proposalId) return column;

    return {
      ...column,
      status: getNewColumnStatus(newData, proposalId),
    };
  });

  return {
    ...state,
    data: newData,
    columns: newColumns,
  };
};

// то же что и changeCellStatus но меняет только значение, без смены статуса ячейки
export const changeCellValue: CustomReducer<IWinnerTableStore, any> = (state, action) => {
  const { proposalId, cellId, approvedVolume } = action.payload;

  const newData = state.data.map(row => {
    if (row.id !== cellId) return row;

    const proposalRow = { ...row[`proposal_${proposalId}`] };

    return getFullFields({
      ...row,
      [`proposal_${proposalId}`]: {
        ...proposalRow,
        approvedVolume: approvedVolume,
        status: proposalRow.status,
      },
    });
  });

  if (state.decisionType === DECISION_TYPE.DISTRIBUTE_VOLUMES) {
    return {
      ...state,
      data: newData,
    };
  }

  const newColumns = state.columns.map(column => {
    if (column.id !== proposalId) return column;

    return {
      ...column,
    };
  });

  return {
    ...state,
    data: newData,
    columns: newColumns,
  };
};

export const changeColumnStatus: CustomReducer<IWinnerTableStore, any> = (
  state,
  action,
) => {
  if (state.decisionType === DECISION_TYPE.DISTRIBUTE_VOLUMES) return;

  const { proposalId } = action.payload;
  let newStatus: STATUS_CELL;

  const newColumns = state.columns.map(column => {
    if (column.id !== proposalId) return column;
    newStatus = getNextStatus(column.status);

    return {
      ...column,
      status: newStatus,
    };
  });

  const newData = state.data.map(row => {
    const proposalRow = { ...row[`proposal_${proposalId}`] };

    return getFullFields({
      ...row,
      [`proposal_${proposalId}`]: {
        ...proposalRow,
        status: newStatus,
      },
    });
  });

  return {
    ...state,
    data: newData,
    columns: newColumns,
  };
};

export const handleHoverColumn: CustomReducer<IWinnerTableStore, any> = (
  state,
  action,
) => {
  if (state.decisionType === DECISION_TYPE.DISTRIBUTE_VOLUMES) return;

  const { proposalId, isHover } = action.payload;

  const newColumns = state.columns.map(column => {
    if (column.id === proposalId) {
      return {
        ...column,
        hoverStatus: isHover ? getNextStatus(column.status) : undefined,
      };
    }

    return column;
  });

  return {
    ...state,
    columns: newColumns,
  };
};

export const decisionType: CustomReducer<IWinnerTableStore, DECISION_TYPE> = (
  state,
  action,
) => ({
  ...state,
  decisionType: action.payload,
});
