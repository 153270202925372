import { inject, injectable } from 'inversify';
import { EGlobalDIKeys } from 'core/di/keys';
import type {
  IApiBufferFactory,
  IApiBufferService,
  IApiBufferServiceSettings,
  TApiBufferConstructor,
} from './types';

@injectable()
class ApiBufferFactory<T extends object = object> implements IApiBufferFactory {
  // #region Properties (1)

  public create = (
    settings: IApiBufferServiceSettings<Partial<T>>,
  ): IApiBufferService<T> => {
    const newBuffer = new this._bufferConstructor(settings);

    return newBuffer;
  };

  // #endregion Properties (1)

  // #region Constructors (1)

  constructor(
    @inject(EGlobalDIKeys.ApiBufferServiceConstructor)
    private readonly _bufferConstructor: TApiBufferConstructor<T>,
  ) {}

  // #endregion Constructors (1)
}

export default ApiBufferFactory;
