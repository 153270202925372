import {
  EAnalyticsLoggerActions,
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerEvents,
  EAnalyticsLoggerItemType,
} from '../types';
import { caseFactory } from '../utils';

export const tenderCardsNavigation = caseFactory({
  name: EAnalyticsLoggerEventNames.TenderCardNavigation,
})
  .event(EAnalyticsLoggerEvents.Navigation)
  .action(EAnalyticsLoggerActions.GoToPage)
  .itemType(EAnalyticsLoggerItemType.Tender);

export const accreditationCardsNavigation = caseFactory({
  name: EAnalyticsLoggerEventNames.AccreditationCardNavigation,
})
  .event(EAnalyticsLoggerEvents.Navigation)
  .action(EAnalyticsLoggerActions.GoToPage)
  .itemType(EAnalyticsLoggerItemType.Company);
