import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useHistoryStackObserver } from 'models/HistoryStack/hooks';

const useInitHistoryStack = () => {
  const stack = useHistoryStackObserver();
  const history = useHistory();

  useEffect(() => {
    stack.subscribe(history);
  }, [history, stack]);
};

export default useInitHistoryStack;
