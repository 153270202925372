import React, { Suspense, lazy, useEffect } from 'react';
import ConfigProvider from 'antd/lib/config-provider';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import { Switch } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { TendUI } from '@10d/tend-ui/theme/TendUI';
import { Layout } from '@10d/tend-ui-primitives';
import { Normalize } from '@10d/tend-ui-styling';
import { MuseoSansCyrl } from '@10d/tend-ui-fonts';
import { Header } from '@10d/tend-ui-header';

import { ISamoletChat } from '@devdept/samolet-chat/dist/types/samoletChat';
import { useSelector } from 'react-redux';
import { ChatProvider, useChat } from 'context/Chat';
import { GlobalStyle } from 'styled';
import Routes from 'pages/Routes';
import { ScrollYPositionProvider } from 'context/ScrollYPositon';
import { ScrollListPositionProvider } from 'context/ScrollListPositon';
import { WebsocketsProvider } from 'context/Websockets';
import VersionChecker from 'features/VersionChecker';
import { ToggleConsoleService } from 'store/common/toggle/ConsoleService';
import { Dialog } from 'components/Dialog';
import { instance } from 'api/request';
import { selectFeature } from 'store/common/toggle';
import withErrorBoundary from 'hocs/withErrorBoundary';
import { useProfileInit } from 'hooks/profile';
import useAfterRedirectUrlRestore from 'hooks/useAfterRedirectUrlRestore';
import { useInitialRequestsToCache } from 'hooks/useInitialRequestsToCache';
import { useModalStoreController } from 'store/Modal';
import useLoggers from 'hooks/useLoggers';
import { useCSI } from 'hooks/useCSI';
import { useDispatchWaffles } from 'hooks/useWaffle';
import { useRegionsObserver } from 'hooks/useRegionsObserver';
import useInitHistoryStack from 'hooks/useHistoryStack';
import { useInitApp } from 'helpers/initApp';
import { useHeader } from 'hooks/useHeader';
import useBindProfileDI from './core/profile/di/useBindProfileDI';
import { FullScreenSpin } from './components/Spin';

const SamoletChat = lazy(() =>
  import('@devdept/samolet-chat').then(({ SamoletChat }) => ({ default: SamoletChat })),
);

const ModalGallery = lazy(() => import('modals/Common/ModalGallery'));

const SuspenseModalGallery = () => (
  <Suspense fallback={null}>
    <ModalGallery />
  </Suspense>
);

const SuspenseChat: React.FC<ISamoletChat> = props => (
  <Suspense fallback={null}>
    <SamoletChat {...props} />
  </Suspense>
);

TendUI.init();

const Root = () => {
  const { isEnabled: isChatHidden } = useSelector(selectFeature('hideChat'));
  const chat = useChat();

  useEffect(() => {
    (window as any).toggle = new ToggleConsoleService();
  }, []);

  return (
    <>
      <GlobalStyle />

      <WebsocketsProvider>
        <VersionChecker>
          <ScrollYPositionProvider>
            <ScrollListPositionProvider>
              {!isChatHidden && <SuspenseChat {...chat} />}

              <Routes />
            </ScrollListPositionProvider>
          </ScrollYPositionProvider>
        </VersionChecker>
      </WebsocketsProvider>

      <SuspenseModalGallery />
    </>
  );
};

const UnderRouterContent: React.FC = () => {
  return (
    <ConfigProvider locale={ruRU}>
      <ChatProvider>
        <Switch>
          <Root />
        </Switch>
      </ChatProvider>
    </ConfigProvider>
  );
};
const UnderRouterWithErrorBoundary = withErrorBoundary(UnderRouterContent);

const WithUi: React.FC = () => {
  const headerProps = useHeader();

  return (
    <TendUI client={instance}>
      <Layout.Root>
        <Normalize />
        <MuseoSansCyrl />
        <Dialog.Styles />
        <Header {...headerProps} />

        <Layout.Main>
          <UnderRouterWithErrorBoundary />
        </Layout.Main>

        <ReactQueryDevtools initialIsOpen={false} />
      </Layout.Root>
    </TendUI>
  );
};
const WithControllers: React.FC = () => {
  const { isInitializated } = useProfileInit();

  useAfterRedirectUrlRestore();
  useInitialRequestsToCache();
  useModalStoreController();
  useLoggers();
  useCSI();
  useDispatchWaffles();
  useRegionsObserver();
  useInitHistoryStack();

  if (!isInitializated) return <FullScreenSpin />;

  return <WithUi />;
};
export const WithDI: React.FC = () => {
  const { isInitializated } = useBindProfileDI();

  useInitApp();

  if (!isInitializated) return <FullScreenSpin />;

  return <WithControllers />;
};
