import {
  authStorage,
  goToAuth,
  logout as logoutOauth,
  setAxiosAuthInterceptor,
} from 'samolet-oauth2';
import { AxiosInstance } from 'axios';
import { jwtAuth } from '../helpers';
import { IAuthProvider } from '../type';

class SamoletProvider implements IAuthProvider {
  private clientId;

  public config;

  constructor() {
    this.clientId = process.env.SOCIAL_AUTH_SAMOLET_KEY;
    this.config = {
      login: this.login.bind(this),
      logout: this.logout.bind(this),
    };
  }

  async init(): Promise<void> {
    await jwtAuth(this.clientId);
  }

  login(): void {
    goToAuth({ clientId: this.clientId });
  }

  logout(): void {
    logoutOauth();
  }

  setAuthInterceptor(instance: AxiosInstance): void {
    setAxiosAuthInterceptor(instance, {
      clientId: this.clientId,
      allowAnonymous: true,
    });
  }

  getToken(): string | undefined {
    return authStorage.getJwtAuthParams()?.access;
  }

  updateToken(cb?: () => Promise<void>): void {
    cb?.();
  }

  getPromisifiedToken(): Promise<string> {
    const token = this.getToken();

    if (token) return Promise.resolve(token);

    return Promise.reject('JWT token is invalid');
  }
}

export const samoletProvider = new SamoletProvider();
