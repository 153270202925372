import { inject, injectable } from 'inversify';
import { ProfileMSDI } from 'core/profile/di/profileModelServiceTypes';
import type { IProfileQueryLayerService } from 'api/apiServices/profile/types';
import type { IProfileModel } from 'models/profile/types';
import { IProfile } from 'types/user';
import { isEmpty } from 'helpers/object';
import type { IProfileController } from './types';

@injectable()
class ProfileController implements IProfileController {
  constructor(
    @inject(ProfileMSDI.ProfileModel) private readonly _model: IProfileModel,
    @inject(ProfileMSDI.ProfileQueryLayerService)
    private readonly _api: IProfileQueryLayerService,
  ) {}

  public getApiService(): IProfileQueryLayerService {
    return this._api;
  }

  public getModel(): IProfileModel {
    return this._model;
  }

  public async load(): Promise<void> {
    let data: IProfile = {} as IProfile;

    try {
      data = await this._api.getProfile();
    } catch (e) {
      // skip errors
    }

    if (isEmpty(data)) {
      const permissions = await this._getPermissions();
      this._model.setSettings({ permissions });

      return;
    }

    this._model.setSettings(data);
  }

  private async _getPermissions(): Promise<string[]> {
    return await this._api.getPermissions();
  }
}

export default ProfileController;
