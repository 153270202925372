import { isNull } from 'lodash';
import { IProposal } from 'types/proposal';
import { CustomReducer, IListItem } from 'types/common';
import { ILotProposalList } from 'types/tender';
import { IProposalStore } from './types';

export const isLoading: CustomReducer<IProposalStore, boolean> = (state, action) => ({
  ...state,
  isLoading: action.payload,
});

export const isPbeValid: CustomReducer<IProposalStore, boolean> = (state, action) => ({
  ...state,
  isPbeValid: action.payload,
});

export const isPbeDirty: CustomReducer<IProposalStore, boolean> = (state, action) => ({
  ...state,
  isPbeDirty: action.payload,
});

export const setIsDirty: CustomReducer<IProposalStore, boolean> = (state, action) => ({
  ...state,
  isDirty: action.payload,
});

export const setProposal: CustomReducer<IProposalStore, IProposal> = (state, action) => ({
  ...state,
  proposal: action.payload,
});

export const setInvalidFields: CustomReducer<
  IProposalStore,
  IProposalStore['invalidFields']
> = (state, action) => ({
  ...state,
  invalidFields: action.payload,
});

export const setChangedItemsIds: CustomReducer<
  IProposalStore,
  IProposalStore['changedItemsIds']
> = (state, action) => ({
  ...state,
  changedItemsIds: action.payload,
});

export const updateProposal: CustomReducer<IProposalStore, Partial<IProposal>> = (
  state,
  action,
) => {
  const proposal = {
    ...state.proposal,
    ...action.payload,
  };

  if (Object.hasOwn(action.payload, 'worksPeriod')) {
    if (isNull(action.payload.worksPeriod)) {
      proposal.worksPeriodFrom = null;
      proposal.worksPeriodTo = null;
    } else if (Array.isArray(action.payload.worksPeriod)) {
      const [from, to] = action.payload.worksPeriod;
      proposal.worksPeriodFrom = from;
      proposal.worksPeriodTo = to;
    }
  }

  return {
    ...state,
    proposal,
  };
};

export const commitProposalVersion: CustomReducer<IProposalStore, undefined> = state => ({
  ...state,
  trace: { ...state.proposal },
});

export const isValidating: CustomReducer<IProposalStore, boolean> = (state, action) => ({
  ...state,
  isValidating: action.payload,
});

export const supplierStatuses: CustomReducer<IProposalStore, IListItem[]> = (
  state,
  action,
) => ({
  ...state,
  supplierStatuses: action.payload,
});

// Proposal List
export const lotList: CustomReducer<IProposalStore, ILotProposalList[]> = (
  state,
  action,
) => ({
  ...state,
  lotList: action.payload,
});

export const isProposalListLoading: CustomReducer<IProposalStore, boolean> = (
  state,
  action,
) => ({
  ...state,
  isProposalListLoading: action.payload,
});
