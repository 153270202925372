import { injectable } from 'inversify';
import { computed, makeObservable, runInAction } from 'mobx';
import AsyncStoreFactory from 'stores/AsyncStore/AsyncStoreFactory';
import type { IAsyncStore, IAsyncStoreSettings } from 'stores/AsyncStore/types';
import { IMobxStore } from 'stores/types';

@injectable()
abstract class BaseStore<Data> implements IMobxStore<Data> {
  abstract data: Data;

  private _asyncStore: IAsyncStore<Data>;

  constructor() {
    makeObservable(this);
  }

  @computed
  get isLoaded() {
    return this._asyncStore?.isLoaded ?? false;
  }

  @computed
  get isLoading() {
    return this._asyncStore?.isLoading ?? false;
  }

  protected _initLoader = (getter: IAsyncStoreSettings<Data>['get']) => {
    this._asyncStore = AsyncStoreFactory({ get: getter });
  };

  protected abstract _loader(): Promise<Data>;

  load = async (): Promise<void> => {
    if (this.isLoaded) {
      return Promise.resolve();
    }
    this._initLoader(this._loader);
    const result = await this._asyncStore.load();
    runInAction(() => {
      this.data = result;
    });
  };
}

export default BaseStore;
