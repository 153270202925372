import {
  Breadcrumbs,
  BrowserClient,
  BrowserTracing,
  Dedupe,
  FunctionToString,
  GlobalHandlers,
  HttpContext,
  InboundFilters,
  LinkedErrors,
  ModuleMetadata,
  Replay,
  TryCatch,
  defaultStackParser,
  getCurrentHub,
  makeFetchTransport,
  reactRouterV5Instrumentation,
} from '@sentry/react';
import { Breadcrumb, BreadcrumbHint } from '@sentry/browser';
import { TAppVersion } from 'api/types';
import { ONE_SECOND } from 'constants/time';

const supportedVersions: Record<TAppVersion, boolean> = {
  [TAppVersion.DEV]: false,
  [TAppVersion.PROD]: true,
  [TAppVersion.STAGE]: true,
  [TAppVersion.E2E]: false,
};

const isSentrySupported = (version: TAppVersion): boolean => supportedVersions[version];

const manageBreadcrumbs = (
  breadcrumb: Breadcrumb,
  hint?: BreadcrumbHint,
): Breadcrumb | null => {
  if (breadcrumb.category === 'ui.click') {
    const testId = hint?.event?.target?.getAttribute('data-testid');
    if (testId) {
      breadcrumb.message += testId;
    }

    return breadcrumb;
  }

  return null;
};

const initSentry = (version: TAppVersion) => {
  try {
    if (!isSentrySupported(version)) return;

    const client = new BrowserClient({
      release: process.env.SENTRY_VERSION || '0.1.0',
      environment: version,
      dsn: process.env.SENTRY_FRONT,
      transport: makeFetchTransport,
      stackParser: defaultStackParser,
      integrations: [
        // standard
        new InboundFilters(),
        new FunctionToString(),
        new TryCatch(),
        new Breadcrumbs(),
        new GlobalHandlers(),
        new LinkedErrors(),
        new Dedupe(),
        new HttpContext(),

        // custom
        new BrowserTracing({
          routingInstrumentation: reactRouterV5Instrumentation(history),
        }),
        new ModuleMetadata(),
        new Replay({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: false,
          minReplayDuration: ONE_SECOND * 15,
          // maxReplayDuration: ONE_MINUTE * 3,
        }),
      ],
      beforeBreadcrumb(breadcrumb, hint) {
        return manageBreadcrumbs(breadcrumb, hint);
      },
      sampleRate: 1,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
      ignoreErrors: [/^.*Loading chunk.*$/],
    });

    getCurrentHub().bindClient(client);

    return;
  } catch (err) {
    console.log('Failed to initialize Sentry with error: ', err);
  }
};

export default initSentry;
