import { inject, injectable } from 'inversify';

import { EGlobalDIKeys } from 'core/di/keys';
import type {
  ISelectionModel,
  ISelectionModelSettings,
  TSelectionModelConstructor,
} from 'models/Selection/types';
import { ISelectionModelFactoryService } from './types';

@injectable()
class SelectionModelFactoryService implements ISelectionModelFactoryService {
  constructor(
    @inject(EGlobalDIKeys.SelectionModelConstructor)
    private readonly _selectionConstructor: TSelectionModelConstructor,
  ) {}

  create<T>(settings: ISelectionModelSettings<T>): ISelectionModel<T> {
    return new this._selectionConstructor(settings);
  }
}

export default SelectionModelFactoryService;
