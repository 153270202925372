import { createSlice } from '@reduxjs/toolkit';
import { addRegions, regions } from './reducer';
import { IRegionStore } from './types';

const initialState: IRegionStore = {
  regions: {},
};

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    regions,
    addRegions,
  },
});

export const actions = regionSlice.actions;
export const reducer = regionSlice.reducer;
