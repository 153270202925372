import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { queryKey } from 'api/types';
import { EWaffleSwitches, TWaffle, apiWaffle, featuresMap } from 'api/api/waffle';
import { ONE_DAY } from 'constants/time';
import { TToggleStore } from 'store/common/toggle/types';
import { toggleActions } from 'store/common/toggle';
import { queryClient } from 'api/queryClient';

interface IUseWaffle {
  waffle: TWaffle;
  getWaffle: () => Promise<QueryObserverResult<TWaffle, unknown>>;
}

export const useWaffle = (): IUseWaffle => {
  const { data: waffle = {} as TWaffle, refetch: getWaffle } = useQuery(
    [queryKey.waffle],
    () => apiWaffle(),
    {
      enabled: false,
      cacheTime: ONE_DAY,
      initialData: () => queryClient.getQueryData([queryKey.waffle]),
    },
  );

  return {
    waffle,
    getWaffle,
  };
};

export const useDispatchWaffles = () => {
  const dispatch = useDispatch();
  const { waffle } = useWaffle();

  const passToStore = useCallback(() => {
    const result: TToggleStore = {} as TToggleStore;

    for (const sw in waffle.switches) {
      result[featuresMap[sw as EWaffleSwitches]] = {
        isEnabled: waffle.switches[sw as EWaffleSwitches].is_active || false,
      };
    }
    dispatch(toggleActions.add(result));
  }, [dispatch, waffle]);

  useEffect(() => {
    passToStore();
  }, [passToStore]);
};
