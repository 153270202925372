import { inject, injectable } from 'inversify';
import type {
  IListArrayModel,
  IListArrayModelSettings,
  TListArrayModelConstructor,
} from 'models/List/types';
import { EGlobalDIKeys } from 'core/di/keys';
import { IListArrayFactoryService } from './types';

@injectable()
class ListArrayFactoryService implements IListArrayFactoryService {
  constructor(
    @inject(EGlobalDIKeys.ListArrayModelConstructor)
    private readonly _listConstructor: TListArrayModelConstructor,
  ) {}

  create<T>(settings: IListArrayModelSettings<T>): IListArrayModel<T> {
    return new this._listConstructor(settings);
  }
}

export default ListArrayFactoryService;
