import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  ISamoletChat,
  ISamoletChatOptions,
} from '@devdept/samolet-chat/dist/types/samoletChat';
import { useModal } from 'hooks/modal';
import { getStandFromEnv } from 'utils/stand';
import { CHAT_KINDS_PROJECT } from 'constants/chat';
import { getCurrentProvider } from 'context/Auth/helpers';

export const ChatData = React.createContext<ISamoletChat>({} as ISamoletChat);

export const ChatProvider: React.FC = ({ children }) => {
  const { show, hide, visible } = useModal();
  const [options, setOptions] = useState<ISamoletChatOptions>({});
  const [countMessages, updateCountMessages] = useState(0);

  const stand = getStandFromEnv();
  const currentProvider = getCurrentProvider();

  const onOpenChat = useCallback(
    (options?: ISamoletChatOptions) => {
      if (options) setOptions(options);
      show();
    },
    [show],
  );

  const onCloseChat = useCallback(() => {
    setOptions({});
    hide();
  }, [hide]);

  const values = useMemo(
    () => ({
      jwt: currentProvider.getToken(),
      kindsProject: CHAT_KINDS_PROJECT,
      stand,
      options,
      visible,
      onOpenChat,
      onCloseChat,
      countMessages,
      updateCountMessages,
    }),
    [countMessages, currentProvider, onCloseChat, onOpenChat, options, stand, visible],
  );

  return <ChatData.Provider value={values}>{children}</ChatData.Provider>;
};

export const useChat = () => useContext(ChatData);
