import { createSlice } from '@reduxjs/toolkit';
import { IProposal } from 'types/proposal';
import {
  commitProposalVersion,
  isLoading,
  isPbeDirty,
  isPbeValid,
  isProposalListLoading,
  isValidating,
  lotList,
  setChangedItemsIds,
  setInvalidFields,
  setIsDirty,
  setProposal,
  supplierStatuses,
  updateProposal,
} from './reducer';
import { IProposalStore } from './types';

const initialState: IProposalStore = {
  isLoading: false,
  proposal: {} as IProposal,
  trace: {} as IProposal,
  supplierStatuses: [],
  lotList: [],
  isProposalListLoading: false,
  isValidating: false,
  isPbeValid: true,
  invalidFields: {},
  changedItemsIds: [],
  isPbeDirty: false,
  isDirty: false,
};

const proposalSlice = createSlice({
  name: 'proposal',
  initialState,
  reducers: {
    isLoading,
    setProposal,
    updateProposal,
    isValidating,
    supplierStatuses,
    lotList,
    isProposalListLoading,
    isPbeValid,
    commitProposalVersion,
    setInvalidFields,
    setChangedItemsIds,
    isPbeDirty,
    setIsDirty,
  },
});

export const actions = proposalSlice.actions;
export const reducer = proposalSlice.reducer;
