import { Paragraph } from '@10d/tend-ui/typography/Paragraph';
import { Text } from '@10d/tend-ui/typography/Text';
import styled, { css } from 'styled-components';

export const TypographyText = styled(Text)<{
  $decoration?: string | false;
  $nowrap?: boolean;
}>`
  ${({ $decoration }) =>
    $decoration &&
    css`
      text-decoration-line: ${$decoration};
    `};

  ${({ $nowrap }) =>
    $nowrap &&
    css`
      text-wrap: nowrap;
    `};
`;
export const TypographyParagraph = styled(Paragraph)`
  margin-bottom: 0 !important;
`;
