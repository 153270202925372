import { injectable } from 'inversify';
import { QueryObserverResult } from '@tanstack/react-query';
import { IWSObserver } from '../WSObserver/types';
import WSObserver from '../WSObserver/WSObserver';
import { WS_HANDLING_STATUS } from '../types';
import type {
  IWSFileConsumerReponse,
  IWSFileHandler,
  IWSFileHandlerSettings,
} from './types';

@injectable()
class WSFileHandler<TData extends IWSFileConsumerReponse>
  implements IWSFileHandler<TData>
{
  private _WSObserver: IWSObserver<TData>;

  private _onSuccess: IWSFileHandlerSettings<TData>['onSuccess'];

  private _onError: IWSFileHandlerSettings<TData>['onError'];

  constructor(settings: IWSFileHandlerSettings<TData>) {
    this._onSuccess = settings.onSuccess;
    this._onError = settings.onError;
    this._WSObserver = new WSObserver({
      callback: this._callback,
      key: settings.key,
    });
  }

  private _callback = (result: QueryObserverResult<TData>): void => {
    if (result.data?.status === WS_HANDLING_STATUS.SUCCESS) {
      this._onSuccess(result.data);

      return;
    }
    if (result.data?.status === WS_HANDLING_STATUS.ERROR) {
      return this._onError?.(result.data);
    }
  };

  unsubscribe = (): void => {
    this._WSObserver.unsubscribe?.();
  };
}

export default WSFileHandler;
