import { combineReducers } from '@reduxjs/toolkit';
import modalStore from './Modal';
import logbookStore from './Logbook';
import proposalStore from './Proposal';
import winnerTableStore from './WinnerTable';
import regionStore from './Region';
import materialsStore from './Materials';
import versionStore from './common/version';
import lotsMetadata from './LotsMetadata';
import toggleStore from './common/toggle';

export default combineReducers({
  modalStore,
  logbookStore,
  proposalStore,
  winnerTableStore,
  lotsMetadata,
  regionStore,
  materialsStore,
  versionStore,
  toggleStore,
});
