import {
  FetchQueryOptions,
  MutationKey,
  MutationOptions,
  QueryKey,
} from '@tanstack/react-query';
import { injectable } from 'inversify';
import { queryClient } from 'api/queryClient';
import { IBaseApiQueryLayer } from './types';

@injectable()
class BaseApiQueryLayer implements IBaseApiQueryLayer {
  protected async _useMutation<T extends (...args: any[]) => any>(
    mutationKey: MutationKey,
    mutationFn: T,
  ): Promise<Awaited<ReturnType<T>>> {
    return (
      queryClient.getQueryData<ReturnType<T>>(mutationKey) ??
      (await this._executeMutation({
        mutationKey,
        mutationFn,
      }))
    );
  }

  private async _executeMutation<
    TData = unknown,
    TError = unknown,
    TVariables = void,
    TContext = unknown,
  >(options: MutationOptions<TData, TError, TVariables, TContext>): Promise<TData> {
    return queryClient.getMutationCache().build(queryClient, options).execute();
  }

  protected async _useQuery<T extends (...args: any[]) => any>(
    queryKey: QueryKey,
    queryFn: T,
    options?: FetchQueryOptions<any, unknown, ReturnType<T>, readonly unknown[]>,
    cache = true,
  ): Promise<Awaited<ReturnType<T>>> {
    let result: Awaited<ReturnType<T>> | undefined;
    if (cache) {
      result = queryClient.getQueryData<Awaited<ReturnType<T>>>(queryKey);
    }

    if (!result) {
      result = await queryClient.fetchQuery({
        ...options,
        queryKey,
        queryFn,
      });
    }

    return result as Awaited<ReturnType<T>>;
  }
}

export default BaseApiQueryLayer;
