import { inject, injectable } from 'inversify';
import { makeObservable, observable } from 'mobx';
import type { ICriteriaApiService } from 'api/apiServices/tenders/tender/criteria/types';
import BaseStore from 'stores/BaseStore';
import { TenderMSDI } from 'features/TenderForms/Edit/helpers/types';
import type { TCriteriaUnits } from './types';

@injectable()
class CriteriaUnitsStore extends BaseStore<TCriteriaUnits> {
  @observable
  data: TCriteriaUnits;

  constructor(
    @inject(TenderMSDI.CriteriaApiServiceQueryLayer)
    private readonly _api: ICriteriaApiService,
  ) {
    super();
    this.data = [];
    makeObservable(this);
  }

  protected _loader = () => {
    return this._api.getUnits();
  };
}

export default CriteriaUnitsStore;
