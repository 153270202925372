import { CaseReducer } from '@reduxjs/toolkit';
import { CustomReducer } from 'types/common/common';
import { IModalAction, IModalStore } from './types';

export const setModal: CustomReducer<IModalStore, IModalAction> = (state, action) => ({
  ...state,
  [action.payload.name]: action.payload.modal,
});

export const clearStore: CaseReducer<IModalStore> = () => ({});
