import UiPage500 from '@devdept/samolet-ui/lib/features/Pages/Page500';
import styled from 'styled-components';

const MENU_HEIGHT = 60;
const LAYOUT_CONTENT_PADDING = 48;

export const Page500 = styled(UiPage500)`
  max-width: 800px;
`;

export const Wrapper = styled.div`
  min-height: calc(100vh - ${MENU_HEIGHT}px - ${LAYOUT_CONTENT_PADDING}px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;
