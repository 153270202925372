import { injectable } from 'inversify';
import { ILoggerService } from './types';

@injectable()
class LoggerService implements ILoggerService {
  private static _currentColor: string | null = null;

  log(str: string): void {
    if (process?.env?.NODE_ENV === 'production') return;
    console.log(...LoggerService._getArgs(str));
  }

  warn(str: string): void {
    if (process?.env?.NODE_ENV === 'production') return;
    console.warn(...LoggerService._getArgs(str));
  }

  error(str: string): void {
    if (process?.env?.NODE_ENV === 'production') return;
    console.error(...LoggerService._getArgs(str));
  }

  private static _colors = [
    'red',
    'green',
    'blue',
    'pink',
    'yellow',
    'black',
    'gray',
    'purple',
    'orange',
    'brown',
  ];

  private static _getArgs(str: string) {
    const result: string[] = [];
    const hasColor = Boolean(LoggerService._currentColor);
    result.push(hasColor ? `%c${str}` : str);
    if (hasColor) {
      result.push(`color: ${LoggerService._currentColor}`);
    }

    return result;
  }

  private static _generateColor() {
    const max = LoggerService._colors.length - 1;
    const min = 0;
    const randomColorIndex = Math.floor(Math.random() * (max - min) + min);

    return LoggerService._colors[randomColorIndex];
  }

  startColor = (color?: string | undefined) => {
    LoggerService._currentColor = color ?? LoggerService._generateColor();
  };

  endColor = () => {
    LoggerService._currentColor = null;
  };
}

export default LoggerService;
