import { colors as uiColors } from '@devdept/samolet-ui/lib/theme/colors';
import { colors as tendColors } from '@10d/tend-ui/tokens/samolet';

export const colors = {
  ...uiColors,
  fieldError: '#ff4d4f',
  red500: '#F36F87',
  border1: '#d7dce9',
  disabled: '#eef0f8',
  gray900: '#343A4A',
  gray500: '#8E919A',
  gray200: '#D2D3D7',
  gray100: '#E8E9EB',
  'gray-4-dividers': '#dee2ed',

  // ant
  antUpload: 'rgba(0, 0, 0, 0.45)',
};

export const tendUiColors = {
  ...tendColors,
};
