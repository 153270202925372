import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useProfileStorage } from 'hooks/profile';
import { ROUTE_TENDERS } from 'constants/routes';
import SentryRoute from 'components/SentryRoute';
import { FullScreenSpin } from 'components/Spin';

const NotEmployeeRoute: React.FC<RouteProps> = props => {
  const {
    profile: { isEmployee },
    isAnonymous,
    isFetching,
  } = useProfileStorage();

  if (isFetching) return <FullScreenSpin $transparentBg />;

  return !isAnonymous && !isEmployee ? (
    <SentryRoute {...props} />
  ) : (
    <Redirect to={ROUTE_TENDERS} />
  );
};

export default NotEmployeeRoute;
