export namespace NBaseTypes {
  export const STATUSES_WIDGET = 'STATUSES_WIDGET';
  export const STATUSES_WIDGET_TITLE = 'STATUSES_WIDGET_TITLE';
}

export enum TENDER_TYPE {
  WAS = 'WAS',
  CAM = 'CAM',
}

export enum TENDER_TYPE_REVERT {
  СМР = 'WAS',
  ТМЦ = 'CAM',
}

export const TENDER_TYPE_SHORT: Record<TENDER_TYPE, string> = {
  [TENDER_TYPE.WAS]: 'СМР',
  [TENDER_TYPE.CAM]: 'ТМЦ',
};

export const TENDER_TYPE_TITLE: Record<TENDER_TYPE, string> = {
  [TENDER_TYPE.WAS]: 'Выполнение работ, оказание услуг',
  [TENDER_TYPE.CAM]: 'Закупка ТМЦ',
};

export enum TENDER_STATE {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  CONFIRM_PARTICIPATION = 'CONFIRM_PARTICIPATION',
  ACCEPT_PROPOSALS = 'ACCEPT_PROPOSALS',
  ACCEPT_PROPOSALS_FINISHED = 'ACCEPT_PROPOSALS_FINISHED',
  ADMIT_PARTICIPANTS = 'ADMIT_PARTICIPANTS',
  ANALYSIS = 'ANALYSIS',
  RESULTS = 'RESULTS',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
}

export const TENDER_STATE_TITLE: Record<TENDER_STATE, string> = {
  [TENDER_STATE.DRAFT]: 'Черновик',
  [TENDER_STATE.PUBLISHED]: 'Опубликован',
  [TENDER_STATE.CONFIRM_PARTICIPATION]: 'Подтверждение участия',
  [TENDER_STATE.ACCEPT_PROPOSALS]: 'Прием предложений',
  [TENDER_STATE.ACCEPT_PROPOSALS_FINISHED]: 'Прием предложений завершен',
  [TENDER_STATE.ADMIT_PARTICIPANTS]: 'Допуск участников',
  [TENDER_STATE.ANALYSIS]: 'Анализ предложений',
  [TENDER_STATE.RESULTS]: 'Подведение итогов',
  [TENDER_STATE.CANCELLED]: 'Отменен',
  [TENDER_STATE.FINISHED]: 'Завершен',
};

export enum TENDER_LIST_FILTER {
  STATE = 'state',
  EMPLOYEE = 'employee',
  OFFERS_END_DATE_FROM = 'offers_end_date_from',
  OFFERS_END_DATE_TO = 'offers_end_date_to',
  FIRST_PUBLICATION_DATE_FROM = 'first_publication_date_from',
  FIRST_PUBLICATION_DATE_TO = 'first_publication_date_to',
  CREATED_DATE_FROM = 'created_date_from',
  CREATED_DATE_TO = 'created_date_to',
  SEARCH = 'search',
  FINISH_DATE_FROM = 'finish_date_from',
  FINISH_DATE_TO = 'finish_date_to',
  TYPE = 'type',
  PROJECT = 'project',
  CONSTRUCTIONS = 'constructions',
  REGION = 'region',
  REGIONS = 'regions',
  SPECIALIZATIONS = 'specializations',
  MATERIALS = 'materials',
  WORK_GROUPS = 'work_groups',
  BASE_PRICE_FROM = 'base_price_from',
  BASE_PRICE_TO = 'base_price_to',
  IS_MINE = 'is_mine',
  IS_FAVORITE = 'is_favorite',
  IS_REBIDDING = 'is_rebidding',
  ORDERING = 'ordering',
  LIMIT = 'limit',
  PAGE = 'page',
}

export interface ITenderListFilter {
  [TENDER_LIST_FILTER.STATE]?: string[];
  [TENDER_LIST_FILTER.EMPLOYEE]?: number[];
  [TENDER_LIST_FILTER.CREATED_DATE_FROM]?: string;
  [TENDER_LIST_FILTER.CREATED_DATE_TO]?: string;
  [TENDER_LIST_FILTER.SEARCH]?: string;
  [TENDER_LIST_FILTER.FINISH_DATE_FROM]?: string;
  [TENDER_LIST_FILTER.FINISH_DATE_TO]?: string;
  [TENDER_LIST_FILTER.TYPE]?: TENDER_TYPE[];
  [TENDER_LIST_FILTER.PROJECT]?: number[];
  [TENDER_LIST_FILTER.CONSTRUCTIONS]?: number[];
  [TENDER_LIST_FILTER.REGION]?: string[];
  [TENDER_LIST_FILTER.SPECIALIZATIONS]?: number[];
  [TENDER_LIST_FILTER.BASE_PRICE_FROM]?: string;
  [TENDER_LIST_FILTER.BASE_PRICE_TO]?: string;
  //[TENDER_LIST_FILTER.IS_MINE]?: boolean; // TODO: uncomment on backend fix
  [TENDER_LIST_FILTER.ORDERING]?: string;
  [TENDER_LIST_FILTER.LIMIT]?: number;
  [TENDER_LIST_FILTER.PAGE]?: number;
}

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};
