import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import type { IModalModel } from './types';

@injectable()
class ModalModel implements IModalModel {
  @action
  public hide = () => {
    this.visible = false;
  };

  @action
  public show = () => {
    this.visible = true;
  };

  @observable
  public visible = false;

  constructor() {
    makeObservable(this);
  }
}

export default ModalModel;
