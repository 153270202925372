import { useEffect, useRef } from 'react';

const usePrevious = (value: string) => {
  const ref = useRef('');

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useAnyPrevious = <T = any>(value: T): T => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
