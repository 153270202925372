import { inject, injectable } from 'inversify';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { DEPARTMENT, IProfile, NPermissions, PERMISSION } from 'types/user';
import { EGlobalDIKeys } from 'core/di/keys';
import type { IModelSettingsManagerFactory } from 'services/settingsManager/types';
import { IModelSettingsManager } from 'services/settingsManager/types';
import type { ILoggerService } from 'services/logger/types';
import type { IProfileModel, TCheckPermissionArgs } from './types';

@injectable()
class ProfileModel implements IProfileModel {
  @observable
  public readonly id: IProfile['id'];

  @observable
  public readonly username: IProfile['username'];

  @observable
  public readonly firstName: IProfile['firstName'];

  @observable
  public readonly lastName: IProfile['lastName'];

  @observable
  public readonly dateJoined: IProfile['dateJoined'];

  @observable
  public readonly email: IProfile['email'];

  @observable
  public readonly isStaff: IProfile['isStaff'];

  @observable
  public readonly isSuperuser: IProfile['isSuperuser'];

  @observable
  public readonly lastLogin: IProfile['lastLogin'];

  @observable
  public readonly loggedIn: IProfile['loggedIn'];

  @observable
  public readonly permissions: IProfile['permissions'];

  private readonly _settingsManager: IModelSettingsManager<IProfileModel>;

  constructor(
    @inject(EGlobalDIKeys.SettingsManagerServiceFactory)
    private readonly _settingsManagerServiceFactory: IModelSettingsManagerFactory<IProfileModel>,
    @inject(EGlobalDIKeys.LoggerService)
    private readonly _loggerService: ILoggerService,
  ) {
    this.permissions = [];
    this._settingsManager = this._settingsManagerServiceFactory.create();
    makeAutoObservable(this);
  }

  public checkPermissions(permissions: TCheckPermissionArgs): boolean {
    if (Array.isArray(permissions)) {
      return permissions.every(permission => this.permissions.includes(permission));
    }

    return this.permissions.includes(permissions);
  }

  @computed
  hasEmployeePermissions(): boolean {
    const departaments: DEPARTMENT[] = [
      DEPARTMENT.ESTIMATOR,
      DEPARTMENT.MATERIAL,
      DEPARTMENT.SECURITY,
      DEPARTMENT.TENDER,
    ];
    for (let i = 0; i < departaments.length; i++) {
      if (this.checkPermissions([PERMISSION[departaments[i]]])) return true;
    }

    return false;
  }

  @action
  public setSettings = (settings: Partial<IProfileModel>): void => {
    this._settingsManager.setSettings(this, settings);
  };

  private _logSetSettings = (propName: keyof IProfileModel): void => {
    if (!(propName in this)) {
      this._loggerService.warn(`Unknown field: ${propName}.`);
    }
    this._loggerService.log(`Property ${propName} has changed.`);
  };

  public canViewTender(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.view]);
  }

  public canEditTender(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.edit]);
  }

  public canCreateTender(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.create]);
  }

  public canAddTenderFaq(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.addFaq]);
  }

  public canViewTenderFaq(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.viewFaq]);
  }

  public canEditTenderFaq(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.editFaq]);
  }

  public canDeleteTenderFaq(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.deleteFaq]);
  }

  public canDownloadAccreditationReport(): boolean {
    return this.checkPermissions(
      NPermissions.List[NPermissions.Tender.downloadAccreditationReport],
    );
  }

  public canDownloadInfocard(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.downloadInfocard]);
  }

  public canChangeTenderOwner(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.changeOwner]);
  }

  public canChangeSignRights(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.changeSignRights]);
  }

  public canCloneTender(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.clone]);
  }

  public canInviteByEmail(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.inviteByEmail]);
  }

  public canInviteBySystem(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.inviteBySystem]);
  }

  public canViewRating(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.viewRating]);
  }

  public canViewParticipants(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.viewParticipants]);
  }

  public canViewProtocol(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.viewProtocol]);
  }

  public canViewResults(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Tender.viewResults]);
  }

  public canViewContracts(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Contracts.view]);
  }

  public canViewOwnContracts(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Contracts.viewOwn]);
  }

  public canDeleteContract(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Contracts.delete]);
  }

  public canViewSupport(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Support.view]);
  }

  public canViewDocuments(): boolean {
    return this.checkPermissions(NPermissions.List[NPermissions.Documents.view]);
  }

  public canViewCompanyProposalsInfo(): boolean {
    return this.checkPermissions(
      NPermissions.List[NPermissions.Companies.viewCompanyProposalsHistory],
    );
  }
}

export default ProfileModel;
