import { CustomReducer } from 'types/common';
import { ILogbookData, ILogbookStore } from './types';

export const isLoading: CustomReducer<ILogbookStore, boolean> = (state, action) => ({
  ...state,
  isLoading: action.payload,
});

export const data: CustomReducer<ILogbookStore, ILogbookData> = (state, action) => ({
  ...state,
  ...action.payload,
});
