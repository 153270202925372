import React from 'react';
import { Status } from '@10d/tend-ui/components';
import { usePush } from 'hooks/routes';

const Forbidden = () => {
  const goToMain = usePush('/');

  return <Status status={403} onClick={goToMain} />;
};

export default Forbidden;
