import { TAppVersion } from '../api/types';

const getFavicon = (): HTMLLinkElement | null =>
  document.querySelector('link[type="image/svg+xml"]');

export const replaceFavicon = (stand: TAppVersion) => {
  const favicon = getFavicon();

  if (!favicon) return;

  if (stand === TAppVersion.PROD) {
    favicon.href = '/favicon.svg';

    return;
  }
  if (stand === TAppVersion.STAGE) {
    favicon.href = '/favicon_stage.svg';

    return;
  }

  favicon.href = '/favicon_dev.svg';
};
