import { useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';

const useResizeBodyObserver = () => {
  const bodyRef = useRef(document.body);
  const { width, height } = useResizeDetector({ targetRef: bodyRef, refreshRate: 200 });

  return { width: width || 0, height: height || 0 };
};

export default useResizeBodyObserver;
