import { createSlice } from '@reduxjs/toolkit';
import { data, isLoading } from './reducer';
import { ILogbookStore } from './types';

const initialState: ILogbookStore = {
  isLoading: false,
  versions: [],
};

const logbookSlice = createSlice({
  name: 'logbook',
  initialState,
  reducers: {
    isLoading,
    data,
  },
});

export const actions = logbookSlice.actions;
export const reducer = logbookSlice.reducer;
