import { AxiosInstance } from 'axios';
import {
  KeyClockAuthService,
  getRealm,
  setAxiosKeyCloakAuthInterceptor,
} from 'samolet-oauth2';
import { LogoType } from '@devdept/samolet-ui/lib/features/Layout/types';
import { IAuthProvider } from '../type';
import { MIN_VALIDITY } from '../constant';

class KeycloakProvider implements IAuthProvider {
  public config;

  public logo;

  constructor() {
    this.config = {
      login: this.login.bind(this),
      logout: this.logout.bind(this),
      logoutUrl: '',
    };

    this.logo = {
      logoUrl: '/',
      logoType: LogoType.CONSTRUCTLY,
      projectTitle: 'S.Tender',
      samoletPosition: false,
    };
  }

  async init(allowAnonymous?: boolean): Promise<void> {
    await KeyClockAuthService.initKeycloak(
      {
        clientId: process.env.KEYCLOAK_OAUTH2_CLIENT_ID ?? '',
        url: process.env.KEYCLOAK_URL,
        realm: getRealm(),
      },
      allowAnonymous,
    );

    this.config = {
      ...this.config,
      logoutUrl: KeyClockAuthService?.getLogoutUrl(),
    };
  }

  login(): void {
    KeyClockAuthService.login();
  }

  logout(): void {
    KeyClockAuthService.logout();
  }

  setAuthInterceptor(instance: AxiosInstance): void {
    setAxiosKeyCloakAuthInterceptor(instance, {
      allowAnonymous: true,
    });
  }

  getToken(): string | undefined {
    return KeyClockAuthService.getToken();
  }

  async updateToken(): Promise<void> {
    const isTokenExpired = KeyClockAuthService._kc.isTokenExpired(MIN_VALIDITY);
    if (isTokenExpired) {
      await KeyClockAuthService.refreshTokens();
    }
  }

  getPromisifiedToken(): Promise<string> {
    const token = this.getToken();

    if (token) Promise.resolve(token);

    return Promise.reject('Keycloak token is invalid');
  }
}

export const keycloakProvider = new KeycloakProvider();
