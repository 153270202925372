import { injectable } from 'inversify';
import { QueryObserver } from '@tanstack/react-query';
import { queryClient } from 'api/queryClient';
import type { IWSObserver, IWSObserverSettings } from './types';

@injectable()
class WSObserver<TData> implements IWSObserver<TData> {
  callback: IWSObserverSettings<TData>['callback'];

  key: IWSObserverSettings<TData>['key'];

  private _queryObserver: QueryObserver<unknown, TData, TData>;

  constructor(settings: IWSObserverSettings<TData>) {
    this.callback = settings.callback;
    this.key = settings.key;

    this._subscribe();
  }

  private _subscribe = () => {
    if (!this.key) throw new Error('Key is required');

    this._queryObserver = new QueryObserver<unknown, TData, TData>(queryClient, {
      queryKey: this.key,
      queryFn: res => res,
    });

    this.unsubscribe = this._queryObserver.subscribe(this.callback);
  };

  unsubscribe: () => void;
}

export default WSObserver;
