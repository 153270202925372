import {
  IAnalyticsLoggerCase,
  IAnalyticsLoggerCaseSettings,
  TAnalyticsLoggerBaseDict,
} from './types';
import AnalyticsLoggerCase from './AnalyticsLoggerCase';

export const caseFactory = <T extends TAnalyticsLoggerBaseDict>(
  settings: IAnalyticsLoggerCaseSettings,
): IAnalyticsLoggerCase<T> => {
  return new AnalyticsLoggerCase<T>(settings);
};
