import { ContainerModule, interfaces } from 'inversify';
import ProfileApiService from 'api/apiServices/profile/ProfileApiService';
import {
  IProfileApiService,
  IProfileQueryKeyService,
  IProfileQueryLayerService,
} from 'api/apiServices/profile/types';
import ProfileQueryLayer from 'api/apiServices/profile/ProfileQueryLayer';
import ProfileQueryKeyService from 'api/apiServices/profile/ProfileQueryKeyService';
import { IProfileModel } from 'models/profile/types';
import ProfileModel from 'models/profile/ProfileModel';
import { IProfileController } from 'controllers/profile/types';
import ProfileController from 'controllers/profile/ProfileController';
import { ProfileMSDI } from './profileModelServiceTypes';

export const profileBindings = new ContainerModule((bind: interfaces.Bind) => {
  bind<IProfileApiService>(ProfileMSDI.ProfileApiService)
    .to(ProfileApiService)
    .inSingletonScope();

  bind<IProfileQueryKeyService>(ProfileMSDI.ProfileQueryKeysService)
    .to(ProfileQueryKeyService)
    .inSingletonScope();

  bind<IProfileQueryLayerService>(ProfileMSDI.ProfileQueryLayerService)
    .to(ProfileQueryLayer)
    .inSingletonScope();

  bind<IProfileModel>(ProfileMSDI.ProfileModel).to(ProfileModel).inSingletonScope();

  bind<IProfileController>(ProfileMSDI.ProfileController)
    .to(ProfileController)
    .inSingletonScope();
});
