import { ContractId } from 'api/api/contract/types';
import { TTenderId } from 'types/tender/tender';
import { getStandFromEnv } from 'utils/stand';
import { TAppVersion } from './types';

export enum API_VERSION {
  V1 = '/api',
  V2 = '/v2',
}

export const API_VERSION_URL = `${API_VERSION.V1}/version/`;

// TENDER
const TENDER_PREFIX = 'tender';
export const API_TENDER_WITH_PREFIX = `${API_VERSION.V1}/${TENDER_PREFIX}`;
export const TENDER_LIST = `${API_TENDER_WITH_PREFIX}/tenders/`;
export const TENDER_LIST_V2 = `${API_VERSION.V2}/tenders/`;
export const TENDER_EMPLOYEE = `${API_TENDER_WITH_PREFIX}/employee/`;

export const createTenderRoute = (
  tenderId: TTenderId,
  route = '',
  version = API_VERSION.V1,
) => {
  return `${
    version === API_VERSION.V1 ? TENDER_LIST : TENDER_LIST_V2
  }${tenderId}/${route}${route?.length ? '/' : ''}`;
};

// CORE
const CORE = 'core';
export const CORE_GROUPS = `${API_VERSION.V1}/${CORE}/groups/`;
export const CORE_REGIONS = `${API_VERSION.V1}/${CORE}/regions/`;
export const CORE_CATALOG_REGIONS = `${API_VERSION.V1}/${CORE}/catalog_regions/`;
export const CORE_CATALOG_REGIONS_WITH_CODES = `${CORE_CATALOG_REGIONS}?with_codes=true&ordering=name`;
export const CORE_MATERIALS = `${API_VERSION.V1}/${CORE}/materials/`;
export const CORE_CLASSIFIERS = `${API_VERSION.V1}/${CORE}/classifiers/`;
export const CORE_KINDS = `${API_VERSION.V1}/${CORE}/workkinds/`;
export const CORE_WORK_GROUPS = `${API_VERSION.V1}/${CORE}/workgroups/`;

// FEATURE TOGGLE
export const WAFFLE_URL = `${API_VERSION.V1}/waffle_status`;

// ACCOUNTS
const PROFILE = 'profile';
const ACCOUNTS = 'accounts';
const PERMISSIONS = 'permissions';

export const API_PROFILE = `/${ACCOUNTS}/${PROFILE}/`;
export const API_PERMISSIONS = `/${ACCOUNTS}/${PERMISSIONS}/`;

// STANDARDS
export const API_STANDARDS = `${API_VERSION.V1}/standards/`;

// CONTRACT
const CONTRACT_PREFIX = 'contract';
export const API_CONTRACT = `${API_VERSION.V1}/${CONTRACT_PREFIX}/`;
export const API_CONTRACTS = `${API_CONTRACT}contracts/`;

export const createContractRoute = (contractId: ContractId, route = '') =>
  `${API_CONTRACTS}${contractId}/${route}${route?.length ? '/' : ''}`;

// ACCREDITATION
export const ACCREDITATION_PREFIX = 'accreditation';
export const API_ACCREDITATION = `${API_VERSION.V1}/${ACCREDITATION_PREFIX}`;
export const API_ACCREDITATION_COMPANIES = `${API_ACCREDITATION}/companies/`;
export const API_ACCREDITATION_ACCREDITATIONS = `${API_ACCREDITATION}/accreditations`;
export const ACCREDITATION_LIST = `${API_ACCREDITATION}/accreditation-requests/`;
export const PROCUREMENT_TYPES = `${API_ACCREDITATION_COMPANIES}procurement_types/`;

// COMPANY
export const COMPANY_PREFIX = 'company';
export const API_COMPANY = `${API_VERSION.V1}/${COMPANY_PREFIX}/`;

// EXCEL INTERFACES
export const createExcelInterfacesRoute = () => `${API_VERSION.V1}/excel_interfaces/`;

// WEBSOCKETS/NOTIFIER
export const API_NOTIFIER = `${API_VERSION.V1}/notifier/`;
export const API_NOTIFIER_TOKEN = `${API_NOTIFIER}get_web_socket_jwt_token/`;

// WEBSOCKETS/SUBSCRIBE
export const API_SUBSCRIBE = `${API_VERSION.V1}/centrifugo/subscribe/`;
export const API_UNSUBSCRIBE = `${API_VERSION.V1}/centrifugo/unsubscribe/`;

const WS_LOCAL_URL = 'ws://localhost:8088/connection/websocket';

export const getWebsocketsUrl = () => {
  const stand = getStandFromEnv();

  if (stand === TAppVersion.DEV) {
    return WS_LOCAL_URL;
  }

  if (!process.env.CENTRIFUGO_WSS_URL) {
    throw new Error('Websockets URL error');
  }

  return process.env.CENTRIFUGO_WSS_URL;
};
