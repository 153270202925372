import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ROUTE_ACCREDITATION,
  ROUTE_COMPANY,
  ROUTE_CONTRACT,
  ROUTE_CONTRACTOR,
  ROUTE_TENDERS,
} from 'constants/routes';
import usePrevious from 'hooks/usePrevious';

const ScrollListPosition = React.createContext({});

const listsRoutes = [
  ROUTE_COMPANY,
  ROUTE_ACCREDITATION,
  ROUTE_CONTRACTOR,
  ROUTE_TENDERS,
  ROUTE_CONTRACT,
];

export const ScrollListPositionProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const [scrollY, setScrollY] = useState(0);
  const layoutContent = document.querySelector('.ant-layout-header + div');
  const prevPathname = usePrevious(pathname);

  const currentPageIsList = listsRoutes.includes(pathname);
  const isNestedPage = prevPathname.includes(pathname);
  const isRouteChanged = prevPathname !== pathname;
  const hasLayoutContent = !!layoutContent;

  const handleScroll = useCallback(event => {
    const currentScroll = event?.target?.scrollTop || 0;

    setScrollY(currentScroll);
  }, []);

  useEffect(() => {
    if (hasLayoutContent && currentPageIsList && isRouteChanged && isNestedPage) {
      layoutContent.scrollTo({
        top: scrollY,
        behavior: 'smooth',
      });
    }
  }, [
    currentPageIsList,
    hasLayoutContent,
    isNestedPage,
    isRouteChanged,
    layoutContent,
    scrollY,
  ]);

  useEffect(() => {
    if (currentPageIsList) {
      hasLayoutContent && layoutContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      hasLayoutContent && layoutContent.removeEventListener('scroll', handleScroll);
    };
  }, [currentPageIsList, handleScroll, hasLayoutContent, layoutContent]);

  const value = {
    scrollY,
  };

  return (
    <ScrollListPosition.Provider value={value}>{children}</ScrollListPosition.Provider>
  );
};
