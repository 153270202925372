import { injectable } from 'inversify';
import { TENDER_TYPE } from '@webcommon/base';
import { get, post } from 'api';
import { IProposalValue } from 'types/proposal';
import { createExcelInterfacesRoute } from 'api/routes';
import { IExcelInterfacesService, IExcelResponse } from './types';

@injectable()
class ExcelInterfacesService implements IExcelInterfacesService {
  private static _apiPath = createExcelInterfacesRoute();

  exportLotXLSX(type: TENDER_TYPE, lotId: number): Promise<IExcelResponse> {
    return get(
      `${
        ExcelInterfacesService._apiPath
      }export_${type.toLowerCase()}_tender_lot/${lotId}/`,
    );
  }

  importLotXLSX(
    type: TENDER_TYPE,
    lotId: number,
    data: FormData,
  ): Promise<IExcelResponse> {
    return post(
      `${
        ExcelInterfacesService._apiPath
      }import_${type.toLowerCase()}_tender_lot/${lotId}/`,
      data,
    );
  }

  exportProposal(
    tenderId: number,
    lotId: number,
    data?: IProposalValue[] | undefined,
  ): Promise<IExcelResponse> {
    return post(
      `${ExcelInterfacesService._apiPath}export_proposal/${tenderId}/${lotId}/`,
      data,
      false,
    );
  }

  importProposal(tenderId: number, lotId: number, data: any): Promise<IExcelResponse> {
    return post(
      `${ExcelInterfacesService._apiPath}parse_excel_proposal/${tenderId}/${lotId}/`,
      data,
      false,
    );
  }

  exportCriteria(tenderId: number): Promise<IExcelResponse> {
    return get(
      `${ExcelInterfacesService._apiPath}export_tender_additional_critera/${tenderId}/`,
    );
  }

  importCriteria(tenderId: number, data: FormData): Promise<IExcelResponse> {
    return post(
      `${ExcelInterfacesService._apiPath}import_tender_additional_critera/${tenderId}/`,
      data,
    );
  }

  getFileHandlingStatus(statusId: number): Promise<IExcelResponse> {
    return get(`${ExcelInterfacesService._apiPath}get_file_handling_status/${statusId}/`);
  }
}

export default ExcelInterfacesService;
