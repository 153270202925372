import { TAppVersion } from 'api/types';
import { getStandFromEnv } from 'utils/stand';

export const EDIT = 'edit';
export const VERSIONS = 'versions';

// COMPANY
export const COMPANY = 'company';
export const ROUTE_COMPANY = `/${COMPANY}`;
export const ROUTE_COMPANY_VIEW = `${ROUTE_COMPANY}/:companyId`;
export const ROUTE_COMPANY_VIEW_TAB = `${ROUTE_COMPANY_VIEW}/:tab`;
export const ROUTE_COMPANY_EDIT = `${ROUTE_COMPANY}/:companyId/${EDIT}`;

// ACCREDITATION
export const ACCREDITATION = 'accreditation';
export const ROUTE_ACCREDITATION = `/${ACCREDITATION}`;
export const ROUTE_ACCREDITATION_VIEW = `${ROUTE_ACCREDITATION}/:companyId`;
export const ROUTE_ACCREDITATION_VIEW_TAB = `${ROUTE_ACCREDITATION_VIEW}/:tab`;
export const ROUTE_ACCREDITATION_VERSIONS = `${ROUTE_ACCREDITATION_VIEW}/${VERSIONS}`;

// CONTRACTOR
export const CONTRACTOR = 'contractor';
export const ROUTE_CONTRACTOR = `/${CONTRACTOR}`;
export const ROUTE_CONTRACTOR_VIEW = `${ROUTE_CONTRACTOR}/:companyId`;
export const ROUTE_CONTRACTOR_VIEW_TAB = `${ROUTE_CONTRACTOR_VIEW}/:tab`;
export const ROUTE_CONTRACTOR_VERSIONS = `${ROUTE_CONTRACTOR_VIEW}/${VERSIONS}`;

//REVIEW
export const REVIEW = 'review';
export const ROUTE_REVIEW = `/${REVIEW}`;
export const ROUTE_REVIEW_VIEW = `${ROUTE_REVIEW}/:companyId/:reviewId`;
export const ROUTE_REVIEW_VIEW_TAB = `${ROUTE_REVIEW}/:companyId/:reviewId/:tab`;

// TENDER
export const TENDERS = 'tenders';
export const ROUTE_TENDERS = `/${TENDERS}`;
export const ROUTE_TENDERS_VIEW = `${ROUTE_TENDERS}/:tenderId`;
export const ROUTE_TENDERS_VIEW_TAB = `${ROUTE_TENDERS}/:tenderId/:tab`;
export const ROUTE_TENDERS_EDIT = `${ROUTE_TENDERS}/:tenderId/${EDIT}`;
export const ROUTE_TENDERS_RESULTS = `${ROUTE_TENDERS}/:tenderId/proposals/winner/`;
export const ROUTE_TENDERS_WINNER = `${ROUTE_TENDERS}/:tenderId/proposals/winner/:lotId/:type`;
export const ROUTE_TENDERS_LOT_PROPOSALS_ANALYSIS = `${ROUTE_TENDERS}/:tenderId/lots/:lotId/proposals/analysis`;

// CONTRACT
export const CONTRACT = 'contract';
export const ROUTE_CONTRACT = `/${CONTRACT}`;
export const ROUTE_CONTRACT_VIEW = `${ROUTE_CONTRACT}/:contractId`;
export const ROUTE_CONTRACT_VIEW_TAB = `${ROUTE_CONTRACT}/:contractId/:tab`;

// AUTH
export const LOGIN = '/accounts/login/samolet/';
export const LOGOUT = '/accounts/logout/';

// DOCUMENTATION
export const DOCUMENTATION = 'documentation';
export const ROUTE_DOCUMENTATION = `/${DOCUMENTATION}`;
export const ROUTE_DOCUMENTATION_TAB = `${ROUTE_DOCUMENTATION}/:tab`;

// SUPPORT
export const SUPPORT = 'support';
export const ROUTE_SUPPORT = `/${SUPPORT}`;

// DOWNLOAD FILES
export const ROUTE_DOWNLOAD = '/download';

// ERRORS
export const ROUTE_FORBIDDEN = '/403';

export enum DOCUMENTATION_TABS_ROUTES {
  COOKIES = 'cookies',
  INSTRUCTIONS = 'instructions',
  PERSONAL_DATA_AGREEMENT = 'agreement',
  PRIVATE_DATA_POLICY = 'policy',
  PLATFORM_REGULATION = 'platformRegulation',
  SOFTWARE_USAGE = 'softwareUsage',
}

export const PRO_SAMOLET_LINK = 'https://pro.samoletgroup.ru/';

export const SCEC_KS2_ACTS_PROD_URL = 'https://scec.samoletgroup.ru/ks2-acts/';
export const SCEC_KS2_ACTS_STAGE_URL = 'https://scec-stage.samoletgroup.ru/ks2-acts/';
export const SCEC_KS2_ACTS_URL =
  getStandFromEnv() === TAppVersion.PROD
    ? SCEC_KS2_ACTS_PROD_URL
    : SCEC_KS2_ACTS_STAGE_URL;
