import { inject, injectable } from 'inversify';
import { EGlobalDIKeys } from 'core/di/keys';
import type {
  IWSFileConsumerReponse,
  IWSFileHandler,
  IWSFileHandlerFactory,
} from 'api/apiServices/ws/WSFileHandler/types';
import type { IExcelAsyncApiService, IExcelAsyncApiServiceSettings } from './types';

@injectable()
class ExcelAsyncApiService<TData> implements IExcelAsyncApiService<TData> {
  private _issuer: IExcelAsyncApiServiceSettings['issuer'];

  private _message: IExcelAsyncApiServiceSettings['message'];

  private _observer: IWSFileHandler<IWSFileConsumerReponse> | null;

  private _currentPromise: Promise<TData> | null;

  constructor(
    settings: IExcelAsyncApiServiceSettings,
    @inject(EGlobalDIKeys.WSFileHandlerFactory)
    private readonly _wsFactory: IWSFileHandlerFactory,
  ) {
    this._issuer = settings.issuer;
    this._message = settings.message;
  }

  private _dispose() {
    this._currentPromise = null;
    this._observer?.unsubscribe?.();
    this._observer = null;
  }

  get message() {
    return this._message;
  }

  run(...args: any[]): Promise<TData> {
    if (this._currentPromise) throw new Error('Already running');

    this._currentPromise = new Promise((resolve, reject) => {
      const onError = (error: any) => {
        this._dispose();
        reject(error);
      };
      const onSuccess = (data: IWSFileConsumerReponse) => {
        this._dispose();
        resolve(data as TData);
      };

      const run = async () => {
        const result = await this._issuer(...args);
        this._observer = this._wsFactory({
          key: [this._message, result.id],
          onError: onError,
          onSuccess: onSuccess,
        });
      };
      run();
    });

    return this._currentPromise;
  }
}

export default ExcelAsyncApiService;
