import {
  EAnalyticsLoggerActions,
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerEvents,
  EAnalyticsLoggerItemType,
} from '../types';
import { caseFactory } from '../utils';

export const tenderTabsNavigation = caseFactory({
  name: EAnalyticsLoggerEventNames.TenderTabsNavigation,
})
  .event(EAnalyticsLoggerEvents.Navigation)
  .action(EAnalyticsLoggerActions.ClickButton)
  .itemType(EAnalyticsLoggerItemType.Tender);
