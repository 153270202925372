import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { useProfileStorage } from 'hooks/profile';
import { getInitialRoute } from './helpers/initialRoute';

const RedirectByRoles: React.FC = () => {
  const { profile } = useProfileStorage();
  const route = useMemo(() => getInitialRoute(profile), [profile]);

  return <Redirect to={route} />;
};

export default RedirectByRoles;
