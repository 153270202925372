import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type useReturn<T = string | undefined> = (pathname?: T) => void;

export const usePush = <T>(path?: string): useReturn<T> => {
  const history = useHistory();

  return useCallback(
    pathname => {
      const route = (typeof pathname === 'string' && pathname) || path;

      route && history.push(route);
    },
    [history, path],
  );
};

export const useReplace = (path: string): useReturn => {
  const history = useHistory();

  return useCallback(pathname => history.replace(pathname || path), [history, path]);
};

export const useGoBack = (): useReturn => {
  const history = useHistory();
  const { pathname } = useLocation();
  const backUrl = String(pathname.split('/').slice(0, -1).join('/'));

  return useCallback(
    (forceUrl?: string) =>
      history.length > 2 ? history.goBack() : history.replace(forceUrl || backUrl),
    [backUrl, history],
  );
};
