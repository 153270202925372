import { ContainerModule, interfaces } from 'inversify';
import { useEffect } from 'react';
import ApiBufferFactory from 'api/apiServices/ApiBufferFactory';
import ApiBufferService from 'api/apiServices/ApiBufferService';
import { IApiBufferFactory, IApiBufferService } from 'api/apiServices/types';
import { EGlobalDIKeys } from 'core/di/keys';
import ApiLoadingStateService from 'services/ApiLoadingStateService';
import LoggerService from 'services/logger/LoggerService';
import { ILoggerService } from 'services/logger/types';
import { globalDIContainer } from 'core/di';
import { TModalModelContructor } from 'models/Modal/types';
import ModalModel from 'models/Modal/ModalModel';
import {
  IList,
  TDynamicListLoader,
  TListArrayModelConstructor,
  TListMapModelConstructor,
} from 'models/List/types';
import ModalModelFactoryService from 'services/modal/ModalModelFactoryService';
import { IModalModelFactoryService } from 'services/modal/types';
import { TSelectionModelConstructor } from 'models/Selection/types';
import SelectionModel from 'models/Selection/SelectionModel';
import { IListArrayFactoryService, IListMapFactoryService } from 'services/list/types';
import ListArrayFactoryService from 'services/list/ListArrayFactoryService';
import { ISelectionModelFactoryService } from 'services/selection/types';
import SelectionModelFactoryService from 'services/selection/SelectionModelFactoryService';
import ListMapModel from 'models/List/ListMapModel';
import ListMapFactoryService from 'services/list/ListMapFactoryService';
import ModelSettingsManager from 'services/settingsManager/ModelSettingsManager';
import ModelSettingsManagerFactory from 'services/settingsManager/ModelSettingsManagerFactory';
import CriteriaTypesStore from 'stores/CriteriaTypes/CriteriaTypes';
import CriteriaUnitsStore from 'stores/CriteriaUnits/CriteriaUnits';
import { IWSObserverSettings } from 'api/apiServices/ws/WSObserver/types';
import WSObserver from 'api/apiServices/ws/WSObserver/WSObserver';
import {
  IWSFileConsumerReponse,
  IWSFileHandlerSettings,
} from 'api/apiServices/ws/WSFileHandler/types';
import WSFileHandler from 'api/apiServices/ws/WSFileHandler/WSFileHandler';
import ExcelInterfacesService from 'api/apiServices/excelInterfaces/ExcelInterfacesService';
import ExcelInterfacesQueryKeysService from 'api/apiServices/excelInterfaces/ExcelInterfacesQueryKeysService';
import ExcelInterfacesServiceQueryLayer from 'api/apiServices/excelInterfaces/ExcelInterfacesServiceQueryLayer';
import { IExcelAsyncApiServiceSettings } from 'services/excelAsyncApiService/types';
import ExcelAsyncApiService from 'services/excelAsyncApiService/ExcelAsyncApiService';
import ExcelAsyncApiServiceManager from 'services/excelAsyncApiService/ExcelAsyncApiServicesManager';
import AWSIApiService from 'api/apiServices/aws/AWSApiService';
import ListArrayModel from 'models/List/ListArrayModel';
import AnalyticsLoggerService from 'services/logger/AnalyticsLoggerService';
import { IAnalyticsLoggerController } from 'controllers/loggers/AnalitycsLoggerController/types';
import AnalitycsLoggerController from 'controllers/loggers/AnalitycsLoggerController';
import {
  IAnalyticsLoggerCaseRegistrator,
  IAnalyticsLoggerService,
} from 'services/logger/AnalyticsLoggerService/types';
import AnalyticsLoggerCaseRegistrator from 'services/logger/AnalyticsLoggerService/AnalyticsLoggerCaseRegistrator';
import DynamicList from 'models/List/DynamicList';
import { IRegionsObserver } from 'controllers/regionsObserver/types';
import RegionsObserver from 'controllers/regionsObserver/RegionsObserver';
import HistoryStack from 'models/HistoryStack/HistoryStack';

export const appModule = new ContainerModule((bind: interfaces.Bind) => {
  bind<IApiBufferService<object>>(
    EGlobalDIKeys.ApiBufferServiceConstructor,
  ).toConstructor(ApiBufferService);
  bind<TModalModelContructor>(EGlobalDIKeys.ModalModelConstructor).toConstructor(
    ModalModel,
  );
  bind<TListArrayModelConstructor>(EGlobalDIKeys.ListArrayModelConstructor).toConstructor(
    ListArrayModel,
  );
  bind<TListMapModelConstructor>(EGlobalDIKeys.ListMapModelConstructor).toConstructor(
    ListMapModel,
  );
  bind<IListMapFactoryService>(EGlobalDIKeys.ListMapModelFactoryService).to(
    ListMapFactoryService,
  );
  bind<IRegionsObserver>(EGlobalDIKeys.RegionsObserver)
    .to(RegionsObserver)
    .inSingletonScope();
  bind<TSelectionModelConstructor>(EGlobalDIKeys.SelectionModelConstructor).toConstructor(
    SelectionModel,
  );

  bind<IModalModelFactoryService>(EGlobalDIKeys.ModalModelFactoryService).to(
    ModalModelFactoryService,
  );
  bind<IListArrayFactoryService>(EGlobalDIKeys.ListArrayModelFactoryService).to(
    ListArrayFactoryService,
  );
  bind<ISelectionModelFactoryService>(EGlobalDIKeys.SelectionModelFactoryService).to(
    SelectionModelFactoryService,
  );

  bind(EGlobalDIKeys.DynamicListFactory).toFactory(() => {
    return <T, List extends IList<T>>(list: List, loader: TDynamicListLoader<T>) => {
      return new DynamicList<T, List>(list, loader);
    };
  });

  bind<IApiBufferFactory>(EGlobalDIKeys.ApiBufferServiceFactory).to(ApiBufferFactory);

  bind<ILoggerService>(EGlobalDIKeys.LoggerService).to(LoggerService).inSingletonScope();
  bind<IAnalyticsLoggerService>(EGlobalDIKeys.AnalyticsLoggerService)
    .to(AnalyticsLoggerService)
    .inSingletonScope();
  bind<IAnalyticsLoggerController>(EGlobalDIKeys.AnalyticsLogger)
    .to(AnalitycsLoggerController)
    .inSingletonScope();
  bind<IAnalyticsLoggerCaseRegistrator>(EGlobalDIKeys.AnalyticsCasesRegistrator)
    .to(AnalyticsLoggerCaseRegistrator)
    .inSingletonScope();

  bind(EGlobalDIKeys.ApiLoadingStateService)
    .to(ApiLoadingStateService)
    .inSingletonScope();

  bind(EGlobalDIKeys.SettingsManagerServiceConstructor).toConstructor(
    ModelSettingsManager,
  );
  bind(EGlobalDIKeys.SettingsManagerServiceFactory)
    .to(ModelSettingsManagerFactory)
    .inSingletonScope();

  bind(EGlobalDIKeys.CriteriaTypesStore).to(CriteriaTypesStore).inSingletonScope();
  bind(EGlobalDIKeys.CriteriaUnitsStore).to(CriteriaUnitsStore).inSingletonScope();

  bind(EGlobalDIKeys.WSObserverFactory).toFactory(() => {
    return (settings: IWSObserverSettings<unknown>) => {
      return new WSObserver(settings);
    };
  });
  bind(EGlobalDIKeys.WSFileHandlerFactory).toFactory(() => {
    return (settings: IWSFileHandlerSettings<IWSFileConsumerReponse>) => {
      return new WSFileHandler(settings);
    };
  });

  bind(EGlobalDIKeys.ExcelInterfacesService).to(ExcelInterfacesService);
  bind(EGlobalDIKeys.ExcelInterfacesQueryKeysService).to(ExcelInterfacesQueryKeysService);
  bind(EGlobalDIKeys.ExcelInterfacesServiceQueryLayer).to(
    ExcelInterfacesServiceQueryLayer,
  );

  bind(EGlobalDIKeys.ExcelAsyncApiServiceFactory).toFactory(context => {
    return (settings: IExcelAsyncApiServiceSettings) => {
      return new ExcelAsyncApiService(
        settings,
        context.container.get(EGlobalDIKeys.WSFileHandlerFactory),
      );
    };
  });

  bind(EGlobalDIKeys.ExcelAsyncApiServicesManagerFactory).toFactory(context => {
    return () => {
      return new ExcelAsyncApiServiceManager(
        context.container.get(EGlobalDIKeys.ListMapModelFactoryService),
        context.container.get(EGlobalDIKeys.ExcelAsyncApiServiceFactory),
      );
    };
  });

  bind(EGlobalDIKeys.AWSApiService).to(AWSIApiService);
  bind(EGlobalDIKeys.HistoryStack).to(HistoryStack).inSingletonScope();
});

export const useInitApp = () => {
  useEffect(() => {
    globalDIContainer.load(appModule);

    return () => {
      globalDIContainer.unload(appModule);
    };
  }, []);
};
