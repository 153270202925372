import { injectable } from 'inversify';
import { action, computed, makeObservable, observable, transaction } from 'mobx';
import type { IListMapModel, IListMapModelSettings } from './types';

@injectable()
class ListMapModel<T, K extends string | number | symbol = string | number | symbol>
  implements IListMapModel<T, K>
{
  // #region Properties (2)

  private _byKey?: keyof T;

  // @observable
  @observable
  public items: Record<string | number | symbol, T>;

  // #endregion Properties (2)

  // #region Constructors (1)

  constructor(settings: IListMapModelSettings<T>) {
    this.items = {} as Record<K, T>;
    this._byKey = settings.byKey;
    this._initMap(settings.items);
    makeObservable(this);
  }

  @action
  addItems = (items: T[]) => {
    transaction(() => items.forEach(this.addItem));
  };

  // #endregion Constructors (1)

  // #region Public Accessors (1)

  @computed
  public get length() {
    return Object.keys(this.items).length;
  }

  // #endregion Public Accessors (1)

  // #region Public Methods (4)

  @action
  public clear = (): void => {
    this.items = {} as IListMapModel<T, K>['items'];
  };

  public getItem = (key: string | number | symbol): T => {
    return this.items[key];
  };

  @action
  public setItems = (items: T[]): void => {
    this._initMap(items);
  };

  @action
  public addItem = (item: T, index?: number): void => {
    const key = (
      this._byKey != null && this._byKey !== 0 ? item[this._byKey] : index
    ) as K;

    this.items = { ...this.items, [key]: item };
  };

  @action
  public removeItem = (key: K): void => {
    delete this.items[key];
  };

  public toArray = (): T[] => {
    return Object.values(this.items);
  };

  // #endregion Public Methods (4)

  // #region Private Methods (1)

  private _initMap = (items: T[]) => {
    const newItems = {} as typeof this.items;
    items.forEach((item, index) => {
      const key = (
        this._byKey != null && this._byKey !== 0 ? item[this._byKey] : index
      ) as K;

      newItems[key] = item;
    });

    this.items = newItems;
  };

  // #endregion Private Methods (1)
}

export default ListMapModel;
