import {
  IProposedValue,
  ITenderView,
  STATUS_CELL,
  TENDER_TYPE,
  TWinnerTableData,
  TWinnerTableRowItem,
  TYPE_CELL,
} from 'types/tender';

// Расчёт всех доп полей
export const getFullFields = (rowItem: TWinnerTableRowItem): TWinnerTableData => {
  const additionalFields = {
    rowStatus: checkRowStatus(rowItem),
    ...getSumRowWinner(rowItem),
  };

  return Object.assign(rowItem, additionalFields);
};

// Расчёт amount / volume
const getSumRowWinner = (rowItem: TWinnerTableRowItem) => {
  const winnerFields: IProposedValue[] = [];

  Object.keys(rowItem).forEach(key => {
    if (rowItem[key]?.status === STATUS_CELL.WINNER) {
      winnerFields.push(rowItem[key]);
    }
  });

  if (!winnerFields.length) {
    return {
      totalAmount: 0,
      totalVolume: 0,
    };
  }

  let total = 0;
  let sumVolume = 0;

  winnerFields.forEach(rowItem => {
    const { approvedVolume, volume, amount } = rowItem;
    const currentVolume = approvedVolume || volume;
    sumVolume += currentVolume;
    total += currentVolume * amount;
  });

  return {
    totalVolume: sumVolume,
    totalAmount: sumVolume ? total / sumVolume : 0,
  };
};

// Смена статуса ячейки
export const getNextStatus = (
  status: STATUS_CELL,
  binaryStatus?: boolean,
): STATUS_CELL => {
  if (binaryStatus) {
    return status !== STATUS_CELL.WINNER ? STATUS_CELL.WINNER : STATUS_CELL.NO_DECISION;
  }

  switch (status) {
    case STATUS_CELL.WINNER:
      return STATUS_CELL.RESERVE;
    case STATUS_CELL.RESERVE:
      return STATUS_CELL.NO_DECISION;
    default:
      return STATUS_CELL.WINNER;
  }
};

// Проверка, есть ли победитель / резервист в строке
// Оптимизация: продумать как сделать за 1 проход
export const checkRowStatus = (rowItem: TWinnerTableRowItem): STATUS_CELL => {
  const proposalsCellArray = Object.keys(rowItem).map(key => rowItem[key]);

  const hasWinner = proposalsCellArray.some(cell => cell?.status === STATUS_CELL.WINNER);

  if (!hasWinner) return STATUS_CELL.WINNER;

  const hasReservist = proposalsCellArray.some(
    cell => cell?.status === STATUS_CELL.RESERVE,
  );

  if (!hasReservist) return STATUS_CELL.RESERVE;

  return STATUS_CELL.NO_DECISION;
};

// Смена статуса столбца
export const getNewColumnStatus = (data: TWinnerTableData[], proposalId: number) => {
  const filterData = data
    .filter(({ typeCell }) => typeCell === TYPE_CELL.DATA)
    .map(row => row[`proposal_${proposalId}`])
    .filter(row => row?.volume);

  const isColumnWinner = filterData.every(cell => cell.status === STATUS_CELL.WINNER);

  if (isColumnWinner) return STATUS_CELL.WINNER;

  const isColumnReservist = filterData.every(cell => cell.status === STATUS_CELL.RESERVE);

  if (isColumnReservist) return STATUS_CELL.RESERVE;

  return STATUS_CELL.NO_DECISION;
};

export const hasDefaultShowRowVolume = (type: ITenderView['type']) => {
  switch (type.toUpperCase()) {
    case TENDER_TYPE.CAM:
    case TENDER_TYPE.WAS:
      return true;
    default:
      return false;
  }
};
