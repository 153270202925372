import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { setUser } from '@sentry/react';
import { ALL_DEPARTMENTS, IProfileExtended, PERMISSION, TPermissions } from 'types/user';
import { queryKey } from 'api/types';
import profileApi from 'api/api/accounts/profile';
import { isEmpty } from 'helpers/object';
import { IUseProfileStore } from './types';

export const useProfileStorage = (): IUseProfileStore => {
  const {
    isLoading,
    isFetching,
    data: profile = {} as IProfileExtended,
    refetch: getProfile,
  } = useQuery([queryKey.profile], () => profileApi.get(), {
    select: res => {
      const department = ALL_DEPARTMENTS.find(department =>
        res?.permissions?.some(permission => permission === PERMISSION[department]),
      );

      return {
        ...res,
        department: department,
        isEmployee: !!department,
        role: department ? 'employee' : 'partner',
      };
    },
    onSuccess: data => {
      data && setUser({ email: data.email, username: data.username });
    },
    retry: 0,
    enabled: process.env.NODE_ENV === 'test',
  });

  /**
   * @deprecated
   *
   * Для проверки прав использовать ProfileModel
   */
  const checkPermissions = useCallback(
    (checkPermissions: TPermissions[]) =>
      checkPermissions.every(permission => profile.permissions?.includes(permission)),
    [profile.permissions],
  );

  return {
    checkPermissions,
    profile,
    isLoading,
    isFetching,
    isAnonymous: isEmpty(profile),
    getProfile,
  };
};
