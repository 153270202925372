import { inject, injectable } from 'inversify';
import type { IModalModel, TModalModelContructor } from 'models/Modal/types';
import { EGlobalDIKeys } from 'core/di/keys';
import { IModalModelFactoryService } from './types';

@injectable()
class ModalModelFactoryService implements IModalModelFactoryService {
  constructor(
    @inject(EGlobalDIKeys.ModalModelConstructor)
    private readonly _modal: TModalModelContructor,
  ) {}

  create(): IModalModel {
    return new this._modal();
  }
}

export default ModalModelFactoryService;
