import { createSlice } from '@reduxjs/toolkit';
import { ILotMetadataStore } from './types';
import {
  addLotMetadata,
  clearLotsMetadata,
  isLotsMetadataLoading,
  lotsMetadata,
  removeLotMetadata,
} from './reducer';

const initialState: ILotMetadataStore = {
  lots: {},
  isLoading: false,
};

const lotMetadataSlice = createSlice({
  name: 'lotMetadataSlice',
  initialState,
  reducers: {
    lotsMetadata,
    addLotMetadata,
    removeLotMetadata,
    clearLotsMetadata,
    isLotsMetadataLoading,
  },
});

export const actions = lotMetadataSlice.actions;
export const reducer = lotMetadataSlice.reducer;
