import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TToggleFeature, TToggleStore } from './types';

const featureSpawner = (): TToggleFeature => ({ isEnabled: false });
const initialState: TToggleStore = {
  csi: { isEnabled: true },
  rating: featureSpawner(),
  rebiddingDrawer: featureSpawner(),
  ym: featureSpawner(),
  denyAnonymous: featureSpawner(),
  lotProposalsAnalysis: featureSpawner(),
  hideChat: featureSpawner(),
  hideRegistrationButton: featureSpawner(),
  hideTelegramAlert: featureSpawner(),
  allPrices: featureSpawner(),
  approvers: featureSpawner(),
  hideContract: featureSpawner(),
  additionalCompanyFields: featureSpawner(),

  newTenderFilter: featureSpawner(),
};

const toggleSlice = createSlice({
  name: 'toggle',
  initialState,
  reducers: {
    set: (state: TToggleStore, { payload }: PayloadAction<TToggleStore>) => {
      return payload;
    },
    add: (state: TToggleStore, { payload }: PayloadAction<TToggleStore>) => {
      return { ...state, ...payload };
    },
    enable: (state: TToggleStore, { payload }: PayloadAction<keyof TToggleStore>) => {
      state[payload].isEnabled = true;
    },
    disable: (state: TToggleStore, { payload }: PayloadAction<keyof TToggleStore>) => {
      state[payload].isEnabled = false;
    },
  },
});

export const toggleReducer = toggleSlice.reducer;
export const toggleActions = toggleSlice.actions;
