/* istanbul ignore file */
import { inject, injectable } from 'inversify';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import { ProfileMSDI } from 'core/profile/di/profileModelServiceTypes';
import type { IProfileModel } from 'models/profile/types';
import { EGlobalDIKeys } from 'core/di/keys';
import type {
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerNamespaces,
  IAnalyticsLoggerCase,
  IAnalyticsLoggerRegistrator,
  IAnalyticsLoggerService,
  TAnalyticsArg,
  TAnalyticsInitializationConfig,
  TAnalyticsLoggerBaseDict,
} from 'services/logger/AnalyticsLoggerService/types';

import store from 'store';
import { selectDisabled } from 'store/common/toggle';
import type { IAnalyticsLoggerController } from './types';

@injectable()
/**
 * Контроллер выступает в роли связки между стейтом фронта и логикой внешней либы
 */
class AnalyticsLoggerController
  implements IAnalyticsLoggerController, IAnalyticsLoggerRegistrator
{
  @observable
  isInited: boolean;

  constructor(
    @inject(ProfileMSDI.ProfileModel) private readonly _profileModel: IProfileModel,
    @inject(EGlobalDIKeys.AnalyticsLoggerService)
    private readonly _analyticsService: IAnalyticsLoggerService &
      IAnalyticsLoggerRegistrator,
  ) {
    this.isInited = false;
    makeAutoObservable(this);
  }

  private _ymAction<T extends (...args: any[]) => void>(func: T) {
    if (!this.isInited) return;

    return func();
  }

  @computed
  isExternal(): boolean {
    return !this._profileModel.hasEmployeePermissions();
  }

  @action
  async init(config: TAnalyticsInitializationConfig) {
    if (selectDisabled('ym')(store.getState())) return;
    await this._analyticsService.init(config);
    this.isInited = true;
  }

  register<TDict extends TAnalyticsLoggerBaseDict = TAnalyticsLoggerBaseDict>(
    name: EAnalyticsLoggerEventNames,
    options: TAnalyticsArg<TDict>,
    namespaces: EAnalyticsLoggerNamespaces[],
  ): void {
    return this._analyticsService.register(name, options, namespaces);
  }

  log<T extends TAnalyticsLoggerBaseDict>(arg: IAnalyticsLoggerCase<T>): void {
    this._ymAction(() =>
      this._analyticsService.log(
        arg.userId(this.userId()).externalUser(this.isExternal() ? 1 : 0),
      ),
    );
  }

  logPageView(): void {
    this._ymAction(() => this._analyticsService.logPageView());
  }

  @computed
  userId(): number {
    return this._profileModel.id;
  }
}

export default AnalyticsLoggerController;
