import { injectable } from 'inversify';
import type { TAnalyticsInitializationConfig, TAnalyticsYandexConfig } from './types';
import {
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerNamespaces,
  IAnalyticsLoggerCase,
  IAnalyticsLoggerRegistrator,
  IAnalyticsLoggerService,
  TAnalyticsArg,
  TAnalyticsLoggerBaseDict,
} from './types';

@injectable()
class AnalyticsLoggerService
  implements IAnalyticsLoggerService, IAnalyticsLoggerRegistrator
{
  private _lib: any;

  log<TDict extends TAnalyticsLoggerBaseDict = TAnalyticsLoggerBaseDict>(
    arg: IAnalyticsLoggerCase<TDict>,
  ): void {
    return this._lib.send(arg.getName(), arg.getData());
  }

  logPageView(): void {
    return this._lib.sendPageView();
  }

  private _map = {
    [EAnalyticsLoggerNamespaces.ya]: this._initYandex.bind(this),
  };

  private _initYandex(config: TAnalyticsYandexConfig) {
    if (!config?.counter)
      throw new Error(`Failed to init Yandex analytics: counter is required.`);

    return this._lib.config({
      [EAnalyticsLoggerNamespaces.ya]: {
        counterId: config.counter,
      },
    });
  }

  register<TDict extends TAnalyticsLoggerBaseDict = TAnalyticsLoggerBaseDict>(
    name: EAnalyticsLoggerEventNames,
    options: TAnalyticsArg<TDict>,
    namespaces: EAnalyticsLoggerNamespaces[] = [EAnalyticsLoggerNamespaces.ya],
  ) {
    namespaces.forEach(namespace => {
      this._lib.events({
        [name]: {
          [namespace]: options,
        },
      });
    });
  }

  async init({ loggers: config }: TAnalyticsInitializationConfig) {
    // При первом же импорте либа инжектит тэг в DOM, а скрипт Яндекса отправляет init запрос. Избавляемся через импорт внутри метода
    const { AnalyticsCounters } = await import('@10d/analytics-counters');
    this._lib = AnalyticsCounters;
    for (const name in config) {
      const typedName = name as keyof typeof this._map;
      if (!this._map[typedName]) continue;

      const configValue = config[name as keyof typeof config];
      this._map[typedName](configValue as NonNullable<typeof configValue>);
    }
  }
}

export default AnalyticsLoggerService;
