import { CustomReducer } from 'types/common';
import { ILot } from 'types/tender';
import { ILotMetadata, ILotMetadataStore, ILotMetadataStoreMap } from './types';

export const lotsMetadata: CustomReducer<ILotMetadataStore, ILotMetadataStoreMap> = (
  state,
  action,
) => ({
  ...state,
  lots: action.payload,
});

export const addLotMetadata: CustomReducer<
  ILotMetadataStore,
  { id: ILot['id']; data: ILotMetadata }
> = (state, { payload: { data, id } }) => {
  state.lots[id] = data;
};

export const removeLotMetadata: CustomReducer<ILotMetadataStore, ILot['id']> = (
  state,
  { payload },
) => {
  delete state.lots[payload];
};

export const clearLotsMetadata: CustomReducer<ILotMetadataStore, void> = state => {
  state.lots = {};
};

export const isLotsMetadataLoading: CustomReducer<ILotMetadataStore, boolean> = (
  state,
  { payload },
) => {
  state.isLoading = payload;
};
