import { initAuth } from 'samolet-oauth2';
import { DEFAULT_PROVIDER, envProvidersMap } from './map';

export const jwtAuth = async (clientId: string | undefined) => {
  if (!clientId) {
    return Promise.reject('Authentification error. Client id is required');
  }

  await initAuth({ clientId, allowAnonymous: true });
};

export const authType = process.env.ENVIRON;

export const getCurrentProvider = () => {
  return envProvidersMap[authType as keyof typeof envProvidersMap] ?? DEFAULT_PROVIDER;
};
