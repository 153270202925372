import { accreditationTabsNavigation } from './accreditationTabsNavigation';
import { tenderTabsNavigation } from './tenderTabsNavigation';
import { leftPanelClick } from './leftPanelClick';
import { filterResult, filters } from './filters';
import * as cards from './listCardsNavigation';
import * as participateTender from './participateTender';
import { createTender, saveOrRemoveTender } from './createTender';
import { actionMenu } from './actionMenu';
import * as versionChecker from './versionChecker';
export default {
  leftPanelClick,
  tenderTabsNavigation,
  accreditationTabsNavigation,
  ...cards,
  ...participateTender,
  filters,
  filterResult,
  createTender,
  saveOrRemoveTender,
  actionMenu,
  ...versionChecker,
};
