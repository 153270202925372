import { inject, injectable } from 'inversify';
import { EGlobalDIKeys } from 'core/di/keys';
import type {
  IModelSettingsManager,
  IModelSettingsManagerFactory,
  TModelSettingsManagerConstructor,
} from './types';

@injectable()
class ModelSettingsManagerFactory<T extends object>
  implements IModelSettingsManagerFactory<T>
{
  constructor(
    @inject(EGlobalDIKeys.SettingsManagerServiceConstructor)
    private readonly _settingsManager: TModelSettingsManagerConstructor<T>,
  ) {}

  create(): IModelSettingsManager<T> {
    return new this._settingsManager();
  }
}

export default ModelSettingsManagerFactory;
