import { useEffect, useState } from 'react';
import { useInjection } from 'inversify-react';
import { IProfileController } from 'controllers/profile/types';
import { ProfileMSDI } from 'core/profile/di/profileModelServiceTypes';

export const useProfileInit = () => {
  const [isInitializated, setIsInitializated] = useState(false);

  const ProfileController = useInjection<IProfileController>(
    ProfileMSDI.ProfileController,
  );

  useEffect(() => {
    const init = async () => {
      try {
        await ProfileController.load();
      } finally {
        setIsInitializated(true);
      }
    };

    init();
  }, [ProfileController]);

  useEffect(() => {
    return () => {
      setIsInitializated(false);
    };
  }, []);

  return {
    isInitializated,
  };
};
