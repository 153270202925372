import { injectable } from 'inversify';
import { QueryKey } from '@tanstack/react-query';
import { queryClient } from 'api/queryClient';
import type { IApiLoadingStateService } from './types';

@injectable()
class ApiLoadingStateService implements IApiLoadingStateService {
  isLoading(key: QueryKey) {
    return queryClient.isFetching(key) > 0;
  }

  isMutating(key: QueryKey) {
    return queryClient.isMutating({ mutationKey: key }) > 0;
  }
}

export default ApiLoadingStateService;
