/* istanbul ignore file */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStandFromEnv } from 'utils/stand';
import useAnalyticsLogger, {
  useAnalyticsCasesRegistrator,
} from 'controllers/loggers/AnalitycsLoggerController/hooks';
import cases from 'services/logger/AnalyticsLoggerService/cases';
import { selectEnabled } from 'store/common/toggle';

const useLoggers = () => {
  const stand = getStandFromEnv();
  const logger = useAnalyticsLogger();
  const registrator = useAnalyticsCasesRegistrator();
  const ymToggle = useSelector(selectEnabled('ym'));

  useEffect(() => {
    if (!logger.isInited) return;

    for (const caseName in cases) {
      registrator.register(cases[caseName as keyof typeof cases]);
    }
  }, [registrator, logger.isInited]);

  useEffect(() => {
    logger.init({
      stand,
      loggers: {
        ya: {
          counter: 95601050,
        },
      },
    });
  }, [stand, logger, ymToggle]);
};

export const useLoggersPagesWatcher = () => {
  const { pathname } = useLocation();
  const logger = useAnalyticsLogger();

  useEffect(() => {
    logger.logPageView();
  }, [pathname, logger]);
};

export default useLoggers;
