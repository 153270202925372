import { TAppVersion, TVersion } from 'api/types';
import { getAppVersion } from 'api/api';

let versionData: TVersion = {
  version: '1',
  stand: TAppVersion.PROD,
};

const getVersion = async (): Promise<TVersion> => {
  versionData = await getAppVersion();

  return versionData;
};

export { versionData, getVersion };
