import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@10d/tend-ui/primitives';
import { FIVE_MINUTES, ONE_MINUTE, ONE_SECOND } from '@webcommon/constants';
import { isValidVersion } from 'helpers/request';
import { getLocalStorage, setLocalStorage } from 'helpers';
import { getStandFromEnv } from 'utils/stand';
import { Moment, getMoment } from 'helpers/time';
import { getAppVersion } from 'api/api';
import useAnalyticsLogger from 'controllers/loggers/AnalitycsLoggerController/hooks';
import { EAnalyticsLoggerEventNames } from 'services/logger/AnalyticsLoggerService/types';
import { caseFactory } from 'services/logger/AnalyticsLoggerService/utils';

const VersionChecker: React.FC = ({ children }) => {
  const stand = getStandFromEnv();
  const storageVersionKey = `stender_${stand}_version`;

  const logger = useAnalyticsLogger();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [reloadTime, setReloadTime] = useState<Moment | null>(null);
  const [lastVersion, setLastVersion] = useState<string>('');

  const handleOk = useCallback(
    (version: string) => {
      const savedVersion = getLocalStorage(storageVersionKey);

      setLocalStorage(storageVersionKey, JSON.stringify(version));

      logger.log(
        caseFactory({
          name: EAnalyticsLoggerEventNames.ModalClick,
        })
          .itemId(`${savedVersion},${version}`)
          .context('Обновить'),
      );

      window.location.reload();
    },
    [storageVersionKey, logger],
  );

  const handleCancel = useCallback(
    (version: string) => {
      const savedVersion = getLocalStorage(storageVersionKey);

      setLastVersion(version);
      setReloadTime(getMoment().add(ONE_MINUTE * 3));

      logger.log(
        caseFactory({
          name: EAnalyticsLoggerEventNames.ModalClick,
        })
          .itemId(`${savedVersion},${version}`)
          .context('Обновить через 3 минуты'),
      );
    },
    [logger, storageVersionKey],
  );

  useEffect(() => {
    getAppVersion().then(({ version }) => {
      setLocalStorage(storageVersionKey, JSON.stringify(version));
    });
  }, [storageVersionKey]);

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (reloadTime) {
      interval = setInterval(() => {
        if (getMoment().isAfter(reloadTime)) {
          setLocalStorage(storageVersionKey, JSON.stringify(lastVersion));
          window.location.reload();
        }
      }, ONE_SECOND);
    }

    return () => (interval ? clearTimeout(interval) : undefined);
  }, [lastVersion, reloadTime, storageVersionKey]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDialogVisible) {
        getAppVersion().then(({ version }) => {
          const savedVersion = getLocalStorage<string>(storageVersionKey);

          if (
            isValidVersion(version.toString()) &&
            isValidVersion(savedVersion) &&
            version.toString() != savedVersion
          ) {
            setIsDialogVisible(true);

            logger.log(
              caseFactory({
                name: EAnalyticsLoggerEventNames.ModalShow,
              }).itemId(`${savedVersion},${version}`),
            );

            Dialog.info({
              title: 'Доступно обновление системы',
              content:
                'Для стабильной работы продукта выполните обновление. Несохраненные данные будут потеряны.',
              closable: false,
              centered: true,
              okType: 'primary',
              okText: 'Обновить',
              cancelText: 'Обновить через 3 минуты',
              onOk: () => handleOk(version),
              onCancel: () => handleCancel(version),
            });
          }
        });
      }
    }, FIVE_MINUTES);

    return () => clearTimeout(interval);
  }, [handleCancel, handleOk, isDialogVisible, storageVersionKey, logger]);

  return <>{children}</>;
};

export default VersionChecker;
