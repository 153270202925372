import { get } from 'api/request';
import { API_VERSION_URL } from 'api/routes';
import { TAppVersion, TVersion } from 'api/types';

const map: Record<string, TAppVersion> = {
  prod: TAppVersion.PROD,
  dev: TAppVersion.DEV,
  stage: TAppVersion.STAGE,
};

export const getAppVersion = async (): Promise<TVersion> => {
  const result: TVersion = await get(API_VERSION_URL);
  result.stand = map[result.stand?.toLowerCase?.()] || map['stage'];

  return result;
};
