export namespace NPermissions {
  export const Tender = {
    view: Symbol(),
    edit: Symbol(),
    addFaq: Symbol(),
    viewFaq: Symbol(),
    editFaq: Symbol(),
    deleteFaq: Symbol(),
    downloadAccreditationReport: Symbol(),
    downloadInfocard: Symbol(),
    changeOwner: Symbol(),
    changeSignRights: Symbol(),
    clone: Symbol(),
    create: Symbol(),
    inviteByEmail: Symbol(),
    inviteBySystem: Symbol(),
    viewRating: Symbol(),
    viewParticipants: Symbol(),
    viewProtocol: Symbol(),
    viewResults: Symbol(),
  };

  export const Contracts = {
    view: Symbol(),
    viewOwn: Symbol(),
    delete: Symbol(),
  };

  export const Documents = {
    view: Symbol(),
  };

  export const Support = {
    view: Symbol(),
  };

  export const Companies = {
    viewCompanyProposalsHistory: Symbol(),
  };

  export const List = {
    // Tender
    // Просмотр тендера
    [Tender.view]: 'tender-tender-view_tender',

    // Редактирование тендера
    [Tender.edit]: 'tender-tender-change_tender',

    // Создание тендера
    [Tender.create]: 'tender-tender-add_tender',

    // Сменить ответственного в тендере
    [Tender.changeOwner]: 'tender-tender-can_assign_somebody',

    // Может переключить подачу КП без подписания
    [Tender.changeSignRights]:
      'lot_participant-lotparticipant-can_add_proposal_without_sign',

    //Копировать тендер в новый черновик
    [Tender.clone]: 'tender-tender-can_clone',

    // Добавить вопрос (FAQ)
    [Tender.addFaq]: 'faq-faq-add_faq',

    // Добавить вопрос (FAQ)
    [Tender.viewFaq]: 'faq-faq-view_faq',

    // Редактировать вопрос (FAQ)
    [Tender.editFaq]: 'faq-faq-change_faq',

    // Удалить вопрос (FAQ)
    [Tender.deleteFaq]: 'faq-faq-delete_faq',

    // Участники -> Отчет по аккредитации (кнопка)
    [Tender.downloadAccreditationReport]: 'tender-tender-can_accreditation_report',

    // Предложения -> Скачать таблицу предложений (кнопка)
    [Tender.downloadInfocard]: 'tender-tender-can_infocard',

    // Пригласить участника по Email
    [Tender.inviteByEmail]: 'tender-tender-can_invite_by_email',

    // Пригласить участника из системы
    [Tender.inviteBySystem]: 'tender-tender-can_invite_from_system',

    // Просмотреть/скачать рейтинг КП
    [Tender.viewRating]: 'proposal-proposal-can_view_rating',

    // Вкладка участники
    // todo
    [Tender.viewParticipants]: '',

    // Вкладка протоколы и результаты
    [Tender.viewProtocol]: 'tender-tender-can_protocol',

    // Предложения -> Результаты тендера (кнопка)
    [Tender.viewResults]: 'tender-tender-view_result',

    // Contracts
    // Просмотр всех
    [Contracts.view]: 'contract-contract-view_contract',

    // Просмотр своих
    [Contracts.viewOwn]: 'contract-contract-view_own_contract',

    // Удаление
    [Contracts.delete]: 'contract-contract-delete_contract',

    // Documents
    // Просмотр раздела
    [Documents.view]: 'user_profile-user-view_menu_documentation',

    // Support
    // Просмотр раздела
    [Support.view]: 'user_profile-user-view_menu_support',

    [Companies.viewCompanyProposalsHistory]:
      'proposal-proposal-can_view_company_proposals_info',
  };
}

export type TUserPermission = (typeof NPermissions.List)[keyof typeof NPermissions.List];

/*************************
 * ⬇️ OLD PERMISSIONS ⬇️
 ************************/
export enum ACCREDITATION {
  CAN_ASSIGN_SOMEBODY = 'CAN_ASSIGN_SOMEBODY',
  CAN_ASSIGN_SELF = 'CAN_ASSIGN_SELF',
  CAN_APPROVE = 'CAN_APPROVE',
  CAN_REJECT = 'CAN_REJECT',
  CAN_BAN_COMPANY = 'CAN_BAN_COMPANY',
  CAN_REQUEST_CLARIFICATIONS = 'CAN_REQUEST_CLARIFICATIONS',
  CAN_VIEW = 'CAN_VIEW',
  CAN_CHANGE = 'CAN_CHANGE',
}

export enum TENDER {
  CAN_ADD = 'CAN_ADD',
  CAN_APPROVE = 'CAN_APPROVE',
  CAN_DELETE = 'CAN_DELETE',
}

export enum DEPARTMENT {
  TENDER = 'TENDER',
  MATERIAL = 'MATERIAL',
  SECURITY = 'SECURITY',
  ESTIMATOR = 'ESTIMATOR',
}

export const PERMISSION = {
  [ACCREDITATION.CAN_ASSIGN_SOMEBODY]: 'accreditation-accreditation-can_assign_somebody',
  [ACCREDITATION.CAN_ASSIGN_SELF]: 'accreditation-accreditation-can_assign_self',
  [ACCREDITATION.CAN_APPROVE]: 'accreditation-accreditation-can_approve',
  [ACCREDITATION.CAN_REJECT]: 'accreditation-accreditation-can_reject',
  [ACCREDITATION.CAN_BAN_COMPANY]: 'accreditation-accreditation-can_ban_company',
  [ACCREDITATION.CAN_REQUEST_CLARIFICATIONS]:
    'accreditation-accreditation-can_request_clarification',
  [ACCREDITATION.CAN_VIEW]: 'accreditation-accreditation-view_accreditation',
  [ACCREDITATION.CAN_CHANGE]: 'accreditation-accreditation-change_accreditation',

  [TENDER.CAN_APPROVE]: 'lot_participant-lotparticipant-can_act_as_security_dep',
  [TENDER.CAN_DELETE]: 'tender-tender-delete_tender',

  [DEPARTMENT.MATERIAL]: 'accreditation-accreditation-can_act_as_material_dep',
  [DEPARTMENT.TENDER]: 'accreditation-accreditation-can_act_as_tender_dep',
  [DEPARTMENT.SECURITY]: 'accreditation-accreditation-can_act_as_security_dep',
  [DEPARTMENT.ESTIMATOR]: 'user_profile-user-is_employee',
};

export type TPermissions = (typeof PERMISSION)[keyof typeof PERMISSION];

export const DEPARTMENT_TITLE: Record<DEPARTMENT, string> = {
  [DEPARTMENT.TENDER]: 'УОТ',
  [DEPARTMENT.MATERIAL]: 'УМТО',
  [DEPARTMENT.SECURITY]: 'СБ',
  [DEPARTMENT.ESTIMATOR]: 'Сметчик',
};

export const ALL_DEPARTMENTS = Object.values(DEPARTMENT);
