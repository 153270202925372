import { injectable } from 'inversify';
import { IProfileExtended } from 'types/user';
import { createApi } from 'api/base/createApi';
import { get, revocableGet } from 'api/request';
import { API_PERMISSIONS, API_PROFILE } from 'api/routes';
import { IProfileApiService } from './types';

@injectable()
class ProfileApiService implements IProfileApiService {
  getProfile = createApi(
    (token): Promise<IProfileExtended> => revocableGet(API_PROFILE, null, token),
  );

  getPermissions(): Promise<string[]> {
    return get(API_PERMISSIONS, null);
  }
}

export default ProfileApiService;
