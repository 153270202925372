import { useEffect, useState } from 'react';
import { globalDIContainer } from 'core/di';
import { profileBindings } from './profileBindings';

const useBindProfileDI = () => {
  const [isInitializated, setIsInitializated] = useState(false);

  useEffect(() => {
    const init = async () => {
      globalDIContainer.load(profileBindings);
    };

    init().then(() => {
      setIsInitializated(true);
    });

    return () => {
      globalDIContainer.unload(profileBindings);
      setIsInitializated(false);
    };
  }, []);

  return {
    isInitializated,
  };
};

export default useBindProfileDI;
