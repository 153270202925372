import { injectable } from 'inversify';
import { post } from 'api';
import { API_VERSION } from 'api/routes';
import { IAWSApiUploadResponse } from 'api/types';
import { IAWSAPiService } from './types';

@injectable()
class AWSIApiService implements IAWSAPiService {
  upload(data: FormData): Promise<IAWSApiUploadResponse> {
    return post(`${API_VERSION.V1}/aws`, data);
  }
}

export default AWSIApiService;
