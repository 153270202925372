import { QueryKey } from '@tanstack/react-query';
import { injectable } from 'inversify';
import { IProfileQueryKeyService } from './types';
import { queryKeys } from './queryKeys';

@injectable()
class ProfileQueryKeyService implements IProfileQueryKeyService {
  getProfile(): QueryKey {
    return [queryKeys.profile];
  }

  getPermissions(): QueryKey {
    return [queryKeys.permissions];
  }
}

export default ProfileQueryKeyService;
