import { CustomReducer, IBackRegion } from 'types/common';
import { IRegionStore, IRegionStoreMap } from './types';

export const regions: CustomReducer<IRegionStore, IRegionStoreMap> = (state, action) => ({
  ...state,
  regions: action.payload,
});

export const addRegions: CustomReducer<IRegionStore, IBackRegion[]> = (
  state,
  { payload },
) => {
  payload?.forEach(el => {
    state.regions = {
      ...state.regions,
      [el.id]: el,
    };
  });
};
