import { inject, injectable } from 'inversify';
import { makeObservable, observable } from 'mobx';
import type { ICriteriaApiService } from 'api/apiServices/tenders/tender/criteria/types';
import BaseStore from 'stores/BaseStore';
import { TenderMSDI } from 'features/TenderForms/Edit/helpers/types';
import type { TCriteriaTypes } from './types';

@injectable()
class CriteriaTypesStore extends BaseStore<TCriteriaTypes> {
  @observable
  data: TCriteriaTypes;

  constructor(
    @inject(TenderMSDI.CriteriaApiServiceQueryLayer)
    private readonly _api: ICriteriaApiService,
  ) {
    super();
    this.data = [];
    makeObservable(this);
  }

  protected _loader = () => {
    return this._api.getTypes();
  };
}

export default CriteriaTypesStore;
