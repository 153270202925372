import { RootState } from 'store';
import { TToggleStore } from './types';

const base = (state: RootState): TToggleStore => state.toggleStore;

export const selectFeature = (name: keyof TToggleStore) => (state: RootState) =>
  base(state)[name];
export const selectEnabled = (name: keyof TToggleStore) => (state: RootState) =>
  selectFeature(name)(state)?.isEnabled ?? false;
export const selectDisabled = (name: keyof TToggleStore) => (state: RootState) =>
  !selectFeature(name)(state)?.isEnabled ?? true;
