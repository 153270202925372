import {
  EAnalyticsLoggerActions,
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerEvents,
  EAnalyticsLoggerItemType,
} from '../types';
import { caseFactory } from '../utils';

export const createTender = caseFactory({
  name: EAnalyticsLoggerEventNames.CreateTender,
})
  .event(EAnalyticsLoggerEvents.CreateTender)
  .action(EAnalyticsLoggerActions.ClickButton)
  .itemType(EAnalyticsLoggerItemType.Tender);

export const saveOrRemoveTender = caseFactory({
  name: EAnalyticsLoggerEventNames.SaveOrRemoveTender,
})
  .event(EAnalyticsLoggerEvents.CreateTender)
  .action(EAnalyticsLoggerActions.SendForm)
  .itemType(EAnalyticsLoggerItemType.Tender);
