import { inject, injectable } from 'inversify';
import { EGlobalDIKeys } from 'core/di/keys';
import { IListMapModel } from 'models/List/types';
import type { IListMapFactoryService } from 'services/list/types';
import type {
  IExcelAsyncApiService,
  IExcelAsyncApiServiceFactory,
  IExcelAsyncApiServicesItem,
  IExcelAsyncApiServicesManager,
} from './types';

@injectable()
class ExcelAsyncApiServiceManager implements IExcelAsyncApiServicesManager {
  private _list: IListMapModel<IExcelAsyncApiService<unknown>, string>;

  constructor(
    @inject(EGlobalDIKeys.ListArrayModel)
    private readonly _listFactory: IListMapFactoryService,
    @inject(EGlobalDIKeys.ExcelAsyncApiServiceFactory)
    private readonly _excelApiFactory: IExcelAsyncApiServiceFactory,
  ) {
    this._list = this._listFactory.create({
      items: [] as IExcelAsyncApiService<unknown>[],
      byKey: 'message',
    });
  }

  register(item: IExcelAsyncApiServicesItem): void {
    this._list.addItem(this._excelApiFactory(item));
  }

  unregister(item: IExcelAsyncApiServicesItem): void {
    this._list.removeItem(item.message);
  }

  run<TData>(key: string, ...args: any[]): Promise<TData> {
    const item = this._list.getItem(key) as IExcelAsyncApiService<TData>;
    if (!item) throw new Error(`Unknown key ${key}`);

    return item.run(...args);
  }
}

export default ExcelAsyncApiServiceManager;
