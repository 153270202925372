export enum CHAT_KINDS {
  ACCREDITATION = 'accreditation',
  TENDERS = 'tenders',
  REVIEWS = 'reviews',
}

export const CHAT_KINDS_PROJECT = [
  CHAT_KINDS.ACCREDITATION,
  CHAT_KINDS.TENDERS,
  CHAT_KINDS.REVIEWS,
];
