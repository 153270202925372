import axios, { CancelToken } from 'axios';
import { CacheApiKey, CreateGetOptions } from 'api/types';

export const ABORTED_STATUS = 'aborted';

// Декоратор. Передаст в переданную функцию токен, который позволяет отменять запрос в случае, если поступил новый,
// а предыдущий еще не выполнен. Рекомендуется использование только для GET.
export const createApi = <R, T = any, Args extends Array<T> = []>(
  func: (token?: CancelToken, ...args: Args) => Promise<R>,
  { singleton }: CreateGetOptions = { singleton: true },
) => {
  const cache: Record<number, CacheApiKey> = {};
  let counter = 0;

  const useCancel = (key: number) => {
    if (!singleton) return;
    const prevToken = cache[key - 1];
    if (prevToken) {
      prevToken.cancel(ABORTED_STATUS);
      delete cache[key - 1];
    }

    const token = axios['CancelToken'].source();
    cache[key] = token;

    return token;
  };

  const clearCache = (key: number) => {
    if (!singleton) return;
    delete cache[key];
  };

  return async (...args: Args): Promise<R> => {
    const key = counter++;
    const token = useCancel(key);
    let result;
    try {
      result = await func(token?.token, ...args);
    } finally {
      clearCache(key);
    }

    return result;
  };
};
