import React, { useMemo } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { FullScreenSpin } from 'components/Spin';
import SentryRoute from 'components/SentryRoute';
import {
  ROUTE_DOCUMENTATION,
  ROUTE_FORBIDDEN,
  ROUTE_SUPPORT,
  ROUTE_TENDERS,
} from 'constants/routes';
import { useProfile, useProfileInit } from 'hooks/profile';

interface IGuardedRoute extends RouteProps {
  permissions: string | string[];
  withRedirectToAnyAvailable?: boolean;
}

const GuardedRoute: React.FC<IGuardedRoute> = ({
  permissions,
  withRedirectToAnyAvailable,
  ...props
}) => {
  const profile = useProfile();
  const { isInitializated } = useProfileInit();

  const redirectUrl = useMemo(() => {
    if (profile.canViewTender()) return ROUTE_TENDERS;

    if (profile.canViewDocuments()) return ROUTE_DOCUMENTATION;

    if (profile.canViewSupport()) return ROUTE_SUPPORT;

    return ROUTE_FORBIDDEN;
  }, [profile]);

  if (!isInitializated) return <FullScreenSpin $transparentBg />;

  return profile.checkPermissions(permissions) ? (
    <SentryRoute {...props} />
  ) : (
    <Redirect to={withRedirectToAnyAvailable ? redirectUrl : ROUTE_TENDERS} />
  );
};

export default GuardedRoute;
