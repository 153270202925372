import { createSlice } from '@reduxjs/toolkit';
import { DECISION_TYPE } from 'types/tender';
import {
  changeCellStatus,
  changeCellValue,
  changeColumnStatus,
  decisionType,
  handleHoverColumn,
  setDecisionType,
  setInitialValues,
  setIsLoadingTable,
} from './reducer';
import { IWinnerTableStore } from './types';

const initialState: IWinnerTableStore = {
  data: [],
  columns: [],
  decisionType: DECISION_TYPE.MANUALLY,
  isLoadingTable: false,
  differentVolume: false,
  showRowVolume: false,
};

const winnerTableSlice = createSlice({
  name: 'winnerTable',
  initialState,
  reducers: {
    setIsLoadingTable,
    decisionType,
    changeCellStatus,
    changeCellValue,
    setDecisionType,
    changeColumnStatus,
    handleHoverColumn,
    setInitialValues,
  },
});

export const actions = winnerTableSlice.actions;
export const reducer = winnerTableSlice.reducer;
