import {
  EAnalyticsLoggerActions,
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerEvents,
  EAnalyticsLoggerItemType,
} from '../types';
import { caseFactory } from '../utils';

export const filters = caseFactory({
  name: EAnalyticsLoggerEventNames.Filters,
})
  .event(EAnalyticsLoggerEvents.Search)
  .action(EAnalyticsLoggerActions.FilterResult)
  .itemType(EAnalyticsLoggerItemType.Tender);

export const filterResult = caseFactory({
  name: EAnalyticsLoggerEventNames.FiltersResult,
})
  .event(EAnalyticsLoggerEvents.Search)
  .action(EAnalyticsLoggerActions.SearchResult)
  .itemType(EAnalyticsLoggerItemType.Tender);
