import { useEffect } from 'react';

export const AFTER_REDIRECT_URL_RESTORE_KEY = 'AFTER_REDIRECT_URL_RESTORE_KEY';

const useAfterRedirectUrlRestore = () => {
  const url = localStorage.getItem(AFTER_REDIRECT_URL_RESTORE_KEY);
  useEffect(() => {
    if (!url) return;
    localStorage.removeItem(AFTER_REDIRECT_URL_RESTORE_KEY);
    location.replace(url as string);
  }, [url]);
};

export default useAfterRedirectUrlRestore;
