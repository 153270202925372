import React, { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';
import { FullScreenSpin } from 'components/Spin';
import { Route as EmployeeRoute } from 'roles/Employee';
import { Route as NotEmployeeRoute } from 'roles/NotEmployee';
import RedirectByRoles from 'roles/Redirect';
import GuardedRoute from 'roles/GuardedRoute';
import { NPermissions, PERMISSION, TENDER } from 'types/user';
import {
  ROUTE_ACCREDITATION,
  ROUTE_COMPANY,
  ROUTE_CONTRACT,
  ROUTE_CONTRACTOR,
  ROUTE_DOCUMENTATION,
  ROUTE_DOWNLOAD,
  ROUTE_FORBIDDEN,
  ROUTE_REVIEW,
  ROUTE_SUPPORT,
  ROUTE_TENDERS,
} from 'constants/routes';
import SentryRoute from 'components/SentryRoute';
import { useLoggersPagesWatcher } from 'hooks/useLoggers';
import { Forbidden } from './Error';

const Accreditation = lazy(() => import('./Accreditation'));
const Company = lazy(() => import('./Company'));
const Contractor = lazy(() => import('./Contractor'));
const Review = lazy(() => import('./Review'));
const Tenders = lazy(() => import('./Tenders'));
const Contract = lazy(() => import('./Contract'));
const Documentation = lazy(() => import('./Documentation'));
const Support = lazy(() => import('./Support'));
const FilesDownloader = lazy(() => import('./FilesDownloader'));

const Routes: React.FC = () => {
  useLoggersPagesWatcher();

  return (
    <Suspense fallback={<FullScreenSpin $transparentBg />}>
      <Switch>
        <SentryRoute path={ROUTE_FORBIDDEN} component={Forbidden} />

        <EmployeeRoute path={ROUTE_CONTRACTOR} component={Contractor} />

        <EmployeeRoute path={ROUTE_ACCREDITATION} component={Accreditation} />

        <NotEmployeeRoute path={ROUTE_COMPANY} component={Company} />

        <EmployeeRoute
          path={ROUTE_REVIEW}
          component={Review}
          permissions={[PERMISSION[TENDER.CAN_APPROVE]]}
        />

        <SentryRoute path={ROUTE_TENDERS} component={Tenders} />

        <SentryRoute path={ROUTE_CONTRACT} component={Contract} />

        <SentryRoute path={ROUTE_DOWNLOAD} component={FilesDownloader} />

        <GuardedRoute
          permissions={NPermissions.List[NPermissions.Documents.view]}
          path={ROUTE_DOCUMENTATION}
          component={Documentation}
        />

        <GuardedRoute
          permissions={NPermissions.List[NPermissions.Support.view]}
          path={ROUTE_SUPPORT}
          component={Support}
        />

        <RedirectByRoles />
      </Switch>
    </Suspense>
  );
};

export default Routes;
