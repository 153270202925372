import { createSlice } from '@reduxjs/toolkit';
import { addLoadedKeys, addMaterials, classifiers, materials } from './reducer';
import { IMaterialsStore } from './types';

const initialState: IMaterialsStore = {
  materials: {},
  loadedClassifiers: [],
  classifiers: {},
};

const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    materials,
    addMaterials,
    classifiers,
    addLoadedKeys,
  },
});

export const actions = materialsSlice.actions;
export const reducer = materialsSlice.reducer;
