import {
  EAnalyticsLoggerActions,
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerEvents,
  EAnalyticsLoggerItemType,
} from '../types';
import { caseFactory } from '../utils';

export const actionMenu = caseFactory({
  name: EAnalyticsLoggerEventNames.ActionMenu,
})
  .event(EAnalyticsLoggerEvents.ActionMenu)
  .action(EAnalyticsLoggerActions.ClickButton)
  .itemType(EAnalyticsLoggerItemType.Tender);
