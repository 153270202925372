import { injectable } from 'inversify';
import { set } from 'lodash';
import { transaction } from 'mobx';
import { IModelSettingsManager } from './types';

@injectable()
class ModelSettingsManager<T extends object> implements IModelSettingsManager<T> {
  setSettings(
    target: T,
    source: Partial<T>,
    callback?: (propName: keyof T) => void,
  ): void {
    if (!target || !source) return;

    transaction(() => {
      for (const propName in source) {
        if (target[propName] === source[propName]) continue;
        callback?.(propName);
        const key = propName as keyof typeof target;
        set(target, key, source[key]);
      }
    });
  }
}

export default ModelSettingsManager;
