import { useEffect } from 'react';
import { CSI } from '@devdept/csi';
import { TStand } from '@devdept/csi/dist/types';
import { useSelector } from 'react-redux';
import { getStandFromEnv } from 'utils/stand';
import { TAppVersion } from 'api/types';
import { CSI_PROJECT_NAME } from 'constants/common';
import { selectEnabled } from 'store/common/toggle';
import { useProfileStorage } from './profile';

const standMap: Record<TAppVersion, TStand> = {
  DEV: 'local',
  PROD: 'production',
  STAGE: 'stage',
  E2E: 'stage',
};
export const useCSI = () => {
  const stand = getStandFromEnv();
  const isEnabled = useSelector(selectEnabled('csi'));
  const { profile } = useProfileStorage();
  const username = profile?.username;

  // Initialization & injection to DOM & run
  useEffect(() => {
    if (!isEnabled) return;

    const timeout = setTimeout(
      () => CSI({ projectName: CSI_PROJECT_NAME, stand: standMap[stand] })(username),
      30000,
    );

    return () => clearTimeout(timeout);
  }, [username, stand, isEnabled]);
};
