import { AxiosRequestConfig, CancelTokenSource } from 'axios';

export type { Method } from 'axios';

export type IUseAsyncReturn = [
  <T>(url: string, options?: T | undefined, isFormData?: boolean) => Promise<any>,
  boolean,
];

export const VALID_STATUSES = [400];

export type AxiosOptions = Omit<AxiosRequestConfig, 'url' | 'method'>;

export type CreateGetOptions = {
  // Отменяет предыдущие запросы, при вызове новых, если ответ на старый еще не получен.
  singleton?: boolean;
};

export type CacheApiKey = CancelTokenSource;

export enum TAppVersion {
  DEV = 'DEV',
  STAGE = 'STAGE',
  PROD = 'PROD',
  E2E = 'E2E',
}
export interface TVersion {
  version: string;
  stand: TAppVersion;
}

export const queryKey = {
  //common
  profile: 'profile',
  specializationsList: 'specializationsList',
  organizationsList: 'organizationsList',
  groupsList: 'groupsList',
  getAppVersion: 'getAppVersion',
  waffle: 'waffle',

  // websockets
  getGwt: 'getGwt',

  //accreditation
  accreditationList: 'accreditationList',
  accreditationHistory: 'accreditationHistory',
  approversList: 'approversList',
  countriesList: 'countriesList',
  procurementTypes: 'procurementTypes',
  showChatAccreditation: 'showChatAccreditation',
  showChatSecurityCheck: 'showChatSecurityCheck',
  getChatRoomAccreditation: 'getChatRoomAccreditation',
  getChatRoomSecurityCheck: 'getChatRoomSecurityCheck',
  accreditationSolution: 'accreditationSolution',

  //company
  financialResultYears: 'financialResultYears',
  companyInfo: 'companyInfo',

  // companies
  company: 'company',
  companiesStates: 'companiesStates',
  contractorList: 'contractorList',
  reviewsList: 'reviewsList',
  showChatReview: 'showChatReview',
  getChatRoomReview: 'getChatRoomReview',
  getCompany: 'getCompany',
  getCompanies: 'getCompanies',

  // contracts
  contract: 'contract',
  contractList: 'contractList',
  contractKinds: 'contractKinds',
  contractStates: 'contractStates',
  contractStatesCount: 'contractStatesCount',
  contractTypes: 'contractTypes',
  contractVats: 'contractVats',
  ksList: 'ksList',

  // tenders
  basePriceRange: 'basePriceRange',
  checkSigningPermitted: 'checkSigningPermitted',
  constructionsList: 'constructionsList',
  criteriaTypes: 'criteriaTypes',
  criteriaUnits: 'criteriaUnits',
  downloadXLSX: 'downloadXLSX',
  faqList: 'faqList',
  getTender: 'getTender',
  getWasAdditionalEmployees: 'getWasAdditionalEmployees', // Deprecated
  getEmpoloyees: 'getEmpoloyees',
  loadProtocols: 'loadProtocols',
  participantsApprovedList: 'participantsApprovedList',
  participantsBlackList: 'participantsBlackList',
  participantsWhiteList: 'participantsWhiteList',
  priceOfferFillings: 'priceOfferFillings',
  projectsList: 'projectsList',
  catalogRegionsList: 'catalogRegionsList',
  showTenderChat: 'showTenderChat',
  tenderCamPriceTypes: 'tenderCamPriceTypes',
  tendersList: 'tendersList',
  tenderTransitions: 'tenderTransitions',
  tenderTransportCost: 'tenderTransportCost',
  tenderWasPriceTypes: 'tenderWasPriceTypes',
  getFileHandlingStatus: 'getFileHandlingStatus',
  getCamTenderResults: 'getCamTenderResults',
  getRoom: 'getRoom',
  getApprovers: 'getApprovers',

  getProposalRating: 'getProposalRating',

  saveEmployee: 'saveEmployee',

  wsGwt: 'wsGwt',
};

export const mutationKeys = {
  tenderClone: 'tenderClone',
  tenderUpdate: 'tenderUpdate',
  tenderRemove: 'tenderRemove',
  tenderCommit: 'tenderCommit',
  toFinished: 'toFinished',
  transition: 'transition',
  saveEmployee: 'saveEmployee',
  whitelistSave: 'whitelistSave',
  whitelistRemove: 'whitelistRemove',
  whitelistReinvite: 'whitelistReinvite',
  uploadFile: 'uploadFile',
  removeFile: 'removeFile',
};

export interface IAWSApiUploadResponse {
  /** url */
  file: string;
}
