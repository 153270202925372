import {
  EAnalyticsLoggerActions,
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerEvents,
  EAnalyticsLoggerItemType,
} from '../types';
import { caseFactory } from '../utils';

export const versionCheckerShow = caseFactory({
  name: EAnalyticsLoggerEventNames.ModalShow,
})
  .event(EAnalyticsLoggerEvents.ModalWindow)
  .action(EAnalyticsLoggerActions.ShowElement)
  .itemType(EAnalyticsLoggerItemType.VersionModal);

export const versionCheckerClick = caseFactory({
  name: EAnalyticsLoggerEventNames.ModalClick,
})
  .event(EAnalyticsLoggerEvents.ModalWindow)
  .action(EAnalyticsLoggerActions.ClickButton)
  .itemType(EAnalyticsLoggerItemType.VersionModal);
