import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { useProfileStorage } from 'hooks/profile';
import { TPermissions } from 'types/user';
import SentryRoute from 'components/SentryRoute';
import { FullScreenSpin } from 'components/Spin';

interface IEmployeeRoute extends RouteProps {
  permissions?: TPermissions[];
}

const EmployeeRoute: React.FC<IEmployeeRoute> = ({ permissions = [], ...props }) => {
  const {
    profile: { isEmployee },
    checkPermissions,
    isFetching,
  } = useProfileStorage();

  if (isFetching) return <FullScreenSpin $transparentBg />;

  return isEmployee && checkPermissions(permissions) ? (
    <SentryRoute {...props} />
  ) : (
    <Redirect to='/' />
  );
};

export default EmployeeRoute;
