import { inject, injectable } from 'inversify';
import { IProfile } from 'types/user';
import { ProfileMSDI } from 'core/profile/di/profileModelServiceTypes';
import BaseApiQueryLayer from 'api/apiServices/BaseApiQueryLayer';
import type {
  IProfileApiService,
  IProfileQueryKeyService,
  IProfileQueryLayerService,
} from './types';

@injectable()
class ProfileQueryLayer extends BaseApiQueryLayer implements IProfileQueryLayerService {
  constructor(
    @inject(ProfileMSDI.ProfileApiService)
    private readonly _Api: IProfileApiService,
    @inject(ProfileMSDI.ProfileQueryKeysService)
    private readonly _QueryKeyService: IProfileQueryKeyService,
  ) {
    super();
  }

  getProfile(): Promise<IProfile> {
    return this._useQuery(this._QueryKeyService.getProfile(), () =>
      this._Api.getProfile(),
    );
  }

  getPermissions(): Promise<string[]> {
    return this._useQuery(this._QueryKeyService.getPermissions(), () =>
      this._Api.getPermissions(),
    );
  }
}

export default ProfileQueryLayer;
