import { createSlice } from '@reduxjs/toolkit';
import { versionData } from 'utils/version';
import { version } from './reducer';
import { IVersionStore } from './types';

const initialState: IVersionStore = {
  version: versionData.stand,
};

const profileSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    version,
  },
});

export const actions = profileSlice.actions;
export const reducer = profileSlice.reducer;
