import { inject, injectable } from 'inversify';
import { TENDER_TYPE } from '@webcommon/base';
import { EGlobalDIKeys } from 'core/di/keys';
import { IProposalValue } from 'types/proposal';
import BaseApiQueryLayer from '../BaseApiQueryLayer';
import type {
  IExcelInterfacesQueryKeysService,
  IExcelInterfacesService,
  IExcelResponse,
} from './types';

@injectable()
class ExcelInterfacesServiceQueryLayer
  extends BaseApiQueryLayer
  implements IExcelInterfacesService
{
  constructor(
    @inject(EGlobalDIKeys.ExcelInterfacesService)
    private readonly _Api: IExcelInterfacesService,
    @inject(EGlobalDIKeys.ExcelInterfacesQueryKeysService)
    private readonly _QueryService: IExcelInterfacesQueryKeysService,
  ) {
    super();
  }

  exportLotXLSX(type: TENDER_TYPE, lotId: number): Promise<IExcelResponse> {
    return this._useQuery(
      this._QueryService.exportLotXLSX(type, lotId),
      () => this._Api.exportLotXLSX(type, lotId),
      {},
      false,
    );
  }

  importLotXLSX(
    type: TENDER_TYPE,
    lotId: number,
    data: FormData,
  ): Promise<IExcelResponse> {
    return this._useMutation(this._QueryService.importLotXLSX(type, lotId), () =>
      this._Api.importLotXLSX(type, lotId, data),
    );
  }

  exportProposal(
    tenderId: number,
    lotId: number,
    data?: IProposalValue[] | undefined,
  ): Promise<IExcelResponse> {
    return this._useMutation(this._QueryService.exportProposal(tenderId, lotId), () =>
      this._Api.exportProposal(tenderId, lotId, data),
    );
  }

  importProposal(tenderId: number, lotId: number, data: any): Promise<IExcelResponse> {
    return this._useMutation(this._QueryService.importProposal(tenderId, lotId), () =>
      this._Api.importProposal(tenderId, lotId, data),
    );
  }

  exportCriteria = (tenderId: number): Promise<IExcelResponse> => {
    return this._useQuery(
      this._QueryService.exportCriteria(tenderId),
      () => this._Api.exportCriteria(tenderId),
      {},
      false,
    );
  };

  importCriteria = (tenderId: number, data: FormData): Promise<IExcelResponse> => {
    return this._useMutation(this._QueryService.importCriteria(tenderId), () =>
      this._Api.importCriteria(tenderId, data),
    );
  };

  getFileHandlingStatus(statusId: number): Promise<IExcelResponse> {
    return this._useQuery(this._QueryService.getFileHandlingStatus(statusId), () =>
      this._Api.getFileHandlingStatus(statusId),
    );
  }
}

export default ExcelInterfacesServiceQueryLayer;
