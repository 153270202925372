import React from 'react';
import { IconProps } from '@10d/tend-ui/icons/types';
import { ITestable } from 'types/common';
import * as uiIcons from './Icons';

const icons = {
  ...uiIcons,
};

export interface IIcon extends IconProps, ITestable {
  type: keyof typeof icons;
}

const Icon: React.FC<IIcon> = ({ type, testId, onClick, style, ...props }) => {
  const Component = icons[type] as React.FC<IconProps>;

  return (
    <Component
      onClick={onClick}
      style={{
        ...style,
        cursor: onClick ? 'pointer' : 'inherit',
      }}
      data-testid={testId}
      {...props}
    />
  );
};

export default Icon;
