import { injectable } from 'inversify';
import { MutationKey, QueryKey } from '@tanstack/react-query';
import { TENDER_TYPE } from '@webcommon/base';
import { IExcelInterfacesQueryKeysService } from './types';
import { excelInterfacesServiceQueryKeys as queryKey } from './queryKeys';
import { excelInterfacesServiceMutationKeys as mutationKeys } from './mutationKeys';

@injectable()
class ExcelInterfacesQueryKeysService implements IExcelInterfacesQueryKeysService {
  exportLotXLSX(type: TENDER_TYPE, lotId: number): QueryKey {
    return [queryKey.exportLotXLSX, type, lotId];
  }

  importLotXLSX(type: TENDER_TYPE, lotId: number): MutationKey {
    return [mutationKeys.importLotXLSX, type, lotId];
  }

  exportProposal(tenderId: number, lotId: number): MutationKey {
    return [mutationKeys.exportProposal, tenderId, lotId];
  }

  importProposal(tenderId: number, lotId: number): MutationKey {
    return [mutationKeys.importProposal, tenderId, lotId];
  }

  exportCriteria(tenderId: number): QueryKey {
    return [queryKey.exportCriteria, tenderId];
  }

  importCriteria(tenderId: number): MutationKey {
    return [mutationKeys.importCriteria, tenderId];
  }

  getFileHandlingStatus(statusId: number): QueryKey {
    return [queryKey.getFileHandlingStatus, statusId];
  }
}

export default ExcelInterfacesQueryKeysService;
