export const enum EGlobalDIKeys {
  HistoryStack = 'HistoryStack',

  ListArrayModel = 'ListArrayModel',
  ListMapModel = 'ListMapModel',
  ListMapModelConstructor = 'ListMapModelConstructor',
  ListArrayModelFactoryService = 'ListArrayModelFactoryService',
  ListMapModelFactoryService = 'ListMapModelFactoryService',

  RegionsObserver = 'RegionsObserver',

  DynamicListFactory = 'DynamicListFactory',

  SelectionModel = 'SelectionModel',
  SelectionModelFactoryService = 'SelectionModelFactoryService',
  ListArrayModelConstructor = 'ListArrayModelConstructor',
  SelectionModelConstructor = 'SelectionModelConstructor',
  ApiLoadingStateService = 'ApiLoadingStateService',
  LoggerService = 'LoggerService',
  AnalyticsLoggerService = 'AnalyticsLoggerService',
  AnalyticsLogger = 'AnalyticsLogger',
  AnalyticsCasesRegistrator = 'AnalyticsCasesRegistrator',
  ApiBufferServiceConstructor = 'ApiBufferServiceConstructor',
  ApiBufferServiceFactory = 'ApiBufferServiceFactory',
  ModalModel = 'ModalModel',
  ModalModelConstructor = 'ModalModelConstructor',
  ModalModelFactoryService = 'ModalModelFactoryService',
  SettingsManagerServiceConstructor = 'SettingsManagerServiceConstructor',
  SettingsManagerServiceFactory = 'SettingsManagerServiceFactory',

  WSObserverFactory = 'WSObserverFactory',
  WSFileHandlerFactory = 'WSFileHandlerFactory',
  ExcelAsyncApiServiceFactory = 'ExcelAsyncApiServiceFactory',
  ExcelAsyncApiServicesManagerFactory = 'ExcelAsyncApiServicesManagerFactory',

  CriteriaUnitsStore = 'CriteriaUnitsStore',
  CriteriaTypesStore = 'CriteriaTypesStore',

  ExcelInterfacesService = 'ExcelInterfacesService',
  ExcelInterfacesServiceQueryLayer = 'ExcelInterfacesServiceQueryLayer',
  ExcelInterfacesQueryKeysService = 'ExcelInterfacesQueryKeysService',

  AWSApiService = 'AWSApiService',
}
