import React, { useMemo } from 'react';
import useResizeBodyObserver from 'hooks/useResizeBodyObserver';
import { EScreenMode } from 'types/screenMode';
import { MOBILE } from 'constants/screenSizes';
import { IAppContext } from './types';

const AppContext = React.createContext<IAppContext>({ mode: EScreenMode.Desktop });

export const AppContextProvider: React.FC = ({ children }) => {
  const { width: bodyWidth } = useResizeBodyObserver();

  const mode =
    bodyWidth <= MOBILE[1] && bodyWidth >= MOBILE[0]
      ? EScreenMode.Mobile
      : EScreenMode.Desktop;

  const value = useMemo(
    () => ({
      mode,
    }),
    [mode],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => React.useContext(AppContext);

export default React.memo(AppContextProvider);
