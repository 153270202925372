import { CustomReducer } from 'types/common';
import { IClassifier, IMaterial } from 'api/api/core/types';
import { IMaterialsStore, IMaterialsStoreMap } from './types';

export const materials: CustomReducer<IMaterialsStore, IMaterialsStoreMap> = (
  state,
  action,
) => ({
  ...state,
  regions: action.payload,
});

export const addMaterials: CustomReducer<IMaterialsStore, IMaterial[]> = (
  state,
  { payload },
) => {
  payload?.forEach(el => {
    state.materials[el.id] = el;
  });
};

export const addLoadedKeys: CustomReducer<IMaterialsStore, IClassifier['id'][]> = (
  state,
  { payload },
) => {
  payload?.forEach(el => {
    state.loadedClassifiers.push(el);
  });
};

export const classifiers: CustomReducer<IMaterialsStore, IClassifier[]> = (
  state,
  { payload },
) => {
  payload?.forEach(classifier => {
    state.classifiers[classifier.id] = classifier;
  });
};
