import {
  EAnalyticsLoggerActions,
  EAnalyticsLoggerEventNames,
  EAnalyticsLoggerEvents,
  EAnalyticsLoggerItemType,
} from '../types';
import { caseFactory } from '../utils';

export const leftPanelClick = caseFactory({
  name: EAnalyticsLoggerEventNames.LeftPanelClick,
})
  .event(EAnalyticsLoggerEvents.LeftSidebar)
  .action(EAnalyticsLoggerActions.ClickButton)
  .itemType(EAnalyticsLoggerItemType.SideMenu);
