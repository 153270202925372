import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import type { ISelectionModel, ISelectionModelSettings } from './types';

/** Deprecated. Использовать ListArrayModel/ListMapModel вместо SelectionModel */
@injectable()
class SelectionModel<T> implements ISelectionModel<T> {
  @observable
  selectedItems: T[];

  constructor(settings: ISelectionModelSettings<T>) {
    this.selectedItems = settings.selectedItems || [];
    makeObservable(this);
  }

  @action
  addSelectedItem = (item: T): void => {
    this.selectedItems.push(item);
  };

  @action
  removeSelectedItem = (item: T): void => {
    if (!this.selectedItems.includes(item)) return;

    this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
  };

  @action
  clear = (): void => {
    this.selectedItems = [];
  };

  @action
  setSelectedItems = (items: T[]): void => {
    this.selectedItems = items;
  };
}

export default SelectionModel;
