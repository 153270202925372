import { RootState } from 'store/index';
import { TModalName } from './types';

export const selectModal =
  (modal: TModalName) =>
  ({ modalStore }: RootState) =>
    modalStore[modal];

export const selectModalsCount = ({ modalStore }: RootState) =>
  Object.keys(modalStore).length;
