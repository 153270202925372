import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ProtoObject } from 'types/common/common';
import { selectModal, selectModalsCount } from './selectors';
import { actions } from './slice';
import { TModalName } from './types';

const { setModal, clearStore } = actions;

export const useModalStore = (modalName: TModalName) => {
  const dispatch = useDispatch();

  const modalRecord = useSelector(selectModal(modalName));

  return useMemo(
    () => ({
      ...modalRecord,
      hide: () =>
        dispatch(setModal({ name: modalName, modal: { visible: false, render: true } })),
      show: (values?: ProtoObject) =>
        dispatch(
          setModal({ name: modalName, modal: { visible: true, render: true, values } }),
        ),
    }),
    [dispatch, modalRecord, modalName],
  );
};

export const useModalStoreController = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const hasModalsRef = useRef(!!useSelector(selectModalsCount));

  useEffect(() => {
    if (hasModalsRef.current) dispatch(clearStore());
  }, [location, hasModalsRef, dispatch]);
};
