import { TAppVersion } from '@devdept/samolet-ui/lib/features/Header/types/common';

export const getStandFromEnv = (): TAppVersion => {
  const envStand = process?.env?.STAND || TAppVersion.DEV;

  switch (envStand) {
    case TAppVersion.PROD:
      return TAppVersion.PROD;
    case TAppVersion.STAGE:
      return TAppVersion.STAGE;
    default:
      return TAppVersion.DEV;
  }
};
