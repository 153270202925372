import { isUndefined } from 'lodash';
import { IProfileExtended } from 'types/user';
import { ROUTE_ACCREDITATION, ROUTE_COMPANY, ROUTE_TENDERS } from 'constants/routes';

type InitialRoute =
  | typeof ROUTE_ACCREDITATION
  | typeof ROUTE_COMPANY
  | typeof ROUTE_TENDERS;

export const getInitialRoute = (profile: IProfileExtended): InitialRoute => {
  if (isUndefined(profile)) return ROUTE_TENDERS;
  const { isEmployee } = profile;

  return isEmployee ? ROUTE_ACCREDITATION : ROUTE_COMPANY;
};
