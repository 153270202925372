import { createReduxEnhancer } from '@sentry/react';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import reducer from './reducers';

const sentryReduxEnhancer = createReduxEnhancer();
const store = configureStore({ reducer, enhancers: [sentryReduxEnhancer] });

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
